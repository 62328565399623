import api from "../api";
import { apiToken } from "../api-helpers";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const reportActions = () => {
  return {
    getAgents: function (data) {
      return api.get(`terminalmapping/api/v1/Admin/agents/GetAllAgents`, {
        headers: headers,
      });
    },

    getAgent: function (data) {
      return api.get(
        `terminalmapping/api/v1/Admin/agents/GetAgentByWalletId?walletId=${data}`,
        {
          headers: headers,
        }
      );
    },
    //Get Terminals
    getTerminals: function (data) {
      const { status, currentPage, searchText, pageSize } = data;
      return api.get(
        `terminalmapping/api/v1/Admin/terminals/GetAllTerminals?CurrentPage=${currentPage}&PageSize=${pageSize}&Status=${status}&SearchText=${searchText}`,
        {
          headers: headers,
        }
      );
    },

    getATerminal: function (data) {
      return api.get(
        `terminalmapping/api/v1/Admin/terminals/GetTerminalById/${data}`,
        {
          headers: headers,
        }
      );
    },

    getTerminalTransactions: function (data) {
      const {
        status,
        currentPage,
        searchText,
        pageSize,
        terminalID,
        startDate,
        endDate,
      } = data;
      return api.get(
        `posnotifications/Admin/terminals/transactions/${terminalID}?CurrentPage=${currentPage}&PageSize=${pageSize}
        &Status=${status}&SearchText=${searchText}&StartDate=${startDate}&EndDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    mapTerminal: function (data) {
      return api.post(`terminalmapping/api/v1/Admin/terminals/map`, data, {
        headers: headers,
      });
    },

    unmapTerminal: function (data) {
      return api.post(`terminalmapping/api/v1/Admin/terminals/unmap`, data, {
        headers: headers,
      });
    },

    postSingleTerminal: function (data) {
      return api.post(`terminalmapping/api/v1/Admin/Terminals/single`, data, {
        headers: headers,
      });
    },

    postMultipleTerminal: function (data) {
      return api.post(`terminalmapping/api/v1/Admin/Terminals/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + apiToken?.accessToken,
        },

      });
    },

    //Terminal Types
    getTerminalTypes: function (data) {
      return api.get(
        `terminalmapping/api/v1/Admin/terminalType/GetAllTerminalType`,
        {
          headers: headers,
        }
      );
    },

    postATerminalType: function (data) {
      return api.post(`terminalmapping/api/v1/Admin/TerminalType`, data, {
        headers: headers,
      });
    },
  };
};

export const TerminalServices = {
  reportActions: reportActions,
};
