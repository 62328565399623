import { ReportTypes } from "./types";

export const ReportsReducer = (state, action) => {
  switch (action.type) {
    case ReportTypes.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ReportTypes.GET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case ReportTypes.GET_AIRTIME: {
      return {
        ...state,
        airtime: action.payload,
      };
    }

    case ReportTypes.GET_WALLET: {
      return {
        ...state,
        wallet: action.payload,
      };
    }

    case ReportTypes.GET_TRANSFERS: {
      return {
        ...state,
        transfers: action.payload,
      };
    }

    case ReportTypes.GET_BILLS: {
      return {
        ...state,
        bills: action.payload,
      };
    }

    case ReportTypes.GET_AGENT_REPORT: {
      return {
        ...state,
        agent: action.payload,
      };
    }

    case ReportTypes.GET_PROVIDER_REPORT: {
      return {
        ...state,
        provider: action.payload,
      };
    }

    default:
      return state;
  }
};
