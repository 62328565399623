import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SuccessNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { TerminalServices } from "./TerminalService";

export const GetTerminals = createAsyncThunk(
  "ls/GetTerminals",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await TerminalServices.terminalActions().getTerminals(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateSingleTerminals = createAsyncThunk(
  "ls/CreateSingleTerminals",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await TerminalServices.terminalActions().postSingleTerminal(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateMultipleTerminals = createAsyncThunk(
  "ls/CreateMultipleTerminals",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await TerminalServices.terminalActions().postMultipleTerminal(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const UpdateSingleTerminals = createAsyncThunk(
  "ls/CreateSingleTerminals",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await TerminalServices.terminalActions().updateSingleTerminal(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetAgentByWalletId = createAsyncThunk(
  "ls/GetAgentByWalletId",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await TerminalServices.terminalActions().getAgent(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const MapTerminal = createAsyncThunk(
  "ls/MapTerminal",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await TerminalServices.terminalActions().mapTerminal(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const UnmapTerminal = createAsyncThunk(
  "ls/MapTerminal",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await TerminalServices.terminalActions().unmapTerminal(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

const initialState = {
  banks: {},
};

export const terminalSlice = createSlice({
  name: "terminals",
  initialState,
  extraReducers: {
    [CreateMultipleTerminals.pending]: (state) => {
      return {
        ...state,
        creating: true,
      };
    },
    [CreateMultipleTerminals.fulfilled]: (state, action) => {
      return {
        ...state,
        bulk_terminals: action.payload,
        creating: false,
      };
    },
    [CreateMultipleTerminals.rejected]: (state, action) => {
      return {
        ...state,
        creating: false,
        error: action.payload,
      };
    },
    [CreateSingleTerminals.pending]: (state) => {
      return {
        ...state,
        creating: true,
      };
    },
    [CreateSingleTerminals.fulfilled]: (state, action) => {
      return {
        ...state,
        single_terminals: action.payload,
        creating: false,
      };
    },
    [CreateSingleTerminals.rejected]: (state, action) => {
      return {
        ...state,
        creating: false,
        error: action.payload,
      };
    },
    [GetTerminals.pending]: (state) => {
      return {
        ...state,
        loadingTerminals: true,
      };
    },
    [GetTerminals.fulfilled]: (state, action) => {
      return {
        ...state,
        terminals: action.payload,
        loadingTerminals: false,
      };
    },
    [GetTerminals.rejected]: (state, action) => {
      return {
        ...state,
        loadingTerminals: false,
        error: action.payload,
      };
    },
    [GetAgentByWalletId.pending]: (state) => {
      return {
        ...state,
        loadingAgent: true,
      };
    },
    [GetAgentByWalletId.fulfilled]: (state, action) => {
      return {
        ...state,
        agent: action.payload,
        loadingAgent: false,
      };
    },
    [GetAgentByWalletId.rejected]: (state, action) => {
      return {
        ...state,
        loadingAgent: false,
        error: action.payload,
      };
    },
    [MapTerminal.pending]: (state) => {
      return {
        ...state,
        loadingTerminalAction: true,
      };
    },
    [MapTerminal.fulfilled]: (state, action) => {
      return {
        ...state,
        terminalAction: action.payload,
        loadingTerminalAction: false,
      };
    },
    [MapTerminal.rejected]: (state, action) => {
      return {
        ...state,
        loadingTerminalAction: false,
        error: action.payload,
      };
    },
  },
});

export default terminalSlice.reducer;
