import React, { createContext } from "react";
import { SideModules } from "./sidebarModules";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const sidebar = SideModules;

  return (
    <SidebarContext.Provider
      value={{
        sidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
