import React, { createContext, useReducer } from "react";
import { exitReponse } from "../api-helpers";
import { AuthTypes } from "../types";
import { ReportsReducer } from "./ReportsReducer";
import { ReportsServices } from "./ReportsServices";
import { ReportTypes } from "./types";

const initialState = {
  loading: false,
  error: false,

  data: [],
  airtime: [],
  wallet: [],
  bills: [],
  transfers: [],
  agent: [],
  provider: [],
};

export const ReportContext = createContext(initialState);

export const ReportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReportsReducer, initialState);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  //User
  //Product Related Calls

  function getDataReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_DATA,
      payload: null,
    });
    ReportsServices.reportActions()
      .getDataTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_DATA,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getAirtimeReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_AIRTIME,
      payload: null,
    });
    ReportsServices.reportActions()
      .getAirtimeTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_AIRTIME,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getWalletReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_WALLET,
      payload: null,
    });
    ReportsServices.reportActions()
      .getWalletTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_WALLET,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getTransfersReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_TRANSFERS,
      payload: null,
    });
    ReportsServices.reportActions()
      .getTransfersTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_TRANSFERS,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getBillsReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_BILLS,
      payload: null,
    });
    ReportsServices.reportActions()
      .getBillsTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_BILLS,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getAgentReport({
    page,
    searchText,
    startDate,
    endDate,
    status,
    provider,
  }) {
    let data = {
      currentPage: page,
      searchText: searchText,
      startDate: startDate,
      endDate: endDate,
      status: status,
      provider: provider,
    };

    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_AGENT_REPORT,
      payload: null,
    });
    ReportsServices.reportActions()
      .getAgents(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_AGENT_REPORT,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getProviderReport(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_PROVIDER_REPORT,
      payload: null,
    });
    ReportsServices.reportActions()
      .getProvider(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_PROVIDER_REPORT,
          payload: res?.data,
        });
        scrollToTop();

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  return (
    <ReportContext.Provider
      value={{
        loading: state.loading,
        data: state.data,
        airtime: state.airtime,
        wallet: state.wallet,
        bills: state.bills,
        transfers: state.transfers,
        agent: state.agent,
        provider: state.provider,
        getAirtimeReport,
        getDataReport,
        getWalletReport,
        getBillsReport,
        getTransfersReport,
        getAgentReport,
        getProviderReport,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
