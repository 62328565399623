//Site Routes
export const HOME = "/";

//App Routes
export const LOGIN = "/login";
export const CREATE = "/create-account";
export const SETPASSWORD = "/set-password";
export const FORGOTPASSWORD = "/forgot-password";
export const SUCCESSPAGE = "/email-confirmation";
export const ERROR = "/*";

//Dashboard Routes
export const DASHBOARD = "/dashboard";
export const DASHBOARDX = "/dashboard";
export const USERS = "users";
export const BULK_UPLOADS = "bulk-uploads";

//Roles Route
export const ROLES = "role-management";
export const CREATEROLES = "create";

//Product Route
export const PRODUCTS = "products";
export const CREATEPRODUCTS = "create-products";
export const CATEGORY = "categories";
export const SERVICES = "services";
export const CREATESERVICES = "create-services";

//Super Dealer
export const CUSTOMERS = "customers";
export const CUSTOMERS_AGENTS = "agent";
export const CUSTOMERS_RESELLERS = "reseller";
export const CUSTOMERS_TELLERS = "teller";
export const CUSTOMERS_CREATE_AGENT = "create-agent";
export const CUSTOMERS_CREATE_CORPRATE_AGENT_UPLOAD = "corporate-agent/upload";
export const CUSTOMERS_CREATE_CORPRATE_AGENT = "corporate-agent";
export const CUSTOMERS_CREATE_RESELLERS = "create-reseller";
export const CUSTOMERS_UPGRADE_TO_RESELLER = "upgrade";
export const CUSTOMERS_SUPER_DEALERS = "superdealer";
export const CUSTOMERS_CREATE_SUPER_DEALERS = "create-superdealer";
export const CUSTOMERS_CREATE_SUPER_DEALERS_WITHOUT_WALLET = "create-superdealer-without-wallet";
export const CUSTOMERS_ADD_PRODUCT_TO_SUPER_DEALER =
  "add-product-to-superdealer";
export const CUSTOMERS_API_CUSTOMERS = "api-customers";
export const CUSTOMERS_CREATE_API_CUSTOMERS = "create-api-customers";

//Notification
export const NOTIFICATION = "banking-and-payments";
export const WALLET_SETTLEMENT = "notification";
export const AGENTS_NOTIFICATION = DASHBOARD + "/agent_notification";

export const VFD = "vfd";
export const UPAY = "upay";
export const LUX = "lux";
export const FINCRA = "fincra";
export const GRUPP = "grupp";
export const PAYSTACK = "paystack";

//WIPAY
export const OTHER_REPORTS = "others";
export const ECOBANK = "ecobank"
export const WIPAY = "wipay";
export const WIPAY_REPORTS = "wipay-reports";

//Audit Route
export const AUDIT = "audit-trail";
export const SINGLEAUDIT = "summary";
export const DASHERROR = "*";

//Reports  Routes
export const REPORTS = "reports";
export const AGENT_LISTING = "agent-listing";
export const TRADING_BREAKDOWN = "trading-breakdown";
export const BANKING_TRAMSACTION = "banking-transaction";
export const BALANCE_SHEET = "balance-sheet";
export const CUSTOMER_WALLET = "customer-wallet";
export const TRANSACTION_LISTING = "transaction-listing";
export const TERMINAL_WALLET = "terminal-wallet";
export const PROVIDER_LISTING = "provider-listing";
export const PROVIDER_WALLET = "provider-wallet";
export const LIEN_TRANSACTIONS = "lien-transactions";

export const SETTLEMENT = "settlements";
export const AIRTIME = "airtime-recharge";
export const DATA_RECHARGE = "data-recharge";
export const BILLS = "bills-payment";
export const TRANSFERS = "transfers";
export const CUSTOMER_CASHFLOW = "customer-cashflow";

//Mapping Routes
export const MAPPING = "terminals";
export const TERMINAL_TYPES = DASHBOARD + "/terminal-types";

//Provider
export const PROVIDER_PAGE = "provider";
export const PROVIDER_RESPONSE = "provider-response";

//Finance
export const FINANCE = "finance";
export const MANUAL_FUNDING = "manual-funding";
export const REVERSAL = "reversal";
export const CHARGES = "charges";
export const NB_CHARGES = "nb_charges";
export const NB_CHARGES_ACTION = "charges-action";
export const CHARGES_ACTION = "charges-action";
export const CHARGES_EDIT = "charges-action/:id";
export const BANKING = "banking";


export const CHANNELS = "channels"
export const ACCOUNTCODE = "account-code";
export const TRANSACTIONLIMIT = "transaction-limit";
export const PROVIDER = "provider";
export const ADD_PROVIDER = "add-provider";
export const ADD_PROVIDER_PRPDUCTS = "add-provider-products";
export const PROVIDER_DETAILS = "provider/:id";
export const VFD_RETRIGGER = "vfd-retrigger";
export const PROCESSOR_SWITCH = "process-switch";
export const CHARGE_BACKS = "charge_backs"
