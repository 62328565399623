import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { exitReponse } from "../context/api-helpers";
import { ProductServices } from "../context/Products/ProductServices";
import { ReportsServices } from "../context/Reports/ReportsServices";
import { SuccessNotification, ErrorNotification } from "../helpers/alerts";

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const ECOBankTransactions = createAsyncThunk(
  "ls/ECOBankTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getECOBANKTransactions(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const ECOBankUploadTransactions = createAsyncThunk(
  "ls/ECOBankUploadTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getECOBANKUploadTransactions(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
       ErrorNotification(error?.response?.data);
      return error?.response;
    }
  }
);

export const WIPAYTransactionRequest = createAsyncThunk(
  "ls/WIPAYTransactionRequest",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getWIPAYTransReq(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      
      return error?.response;
    }
  }
);
export const WIPAYTransactionNotification = createAsyncThunk(
  "ls/WIPAYTransactionNotification",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getWIPAYTransNot(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const WIPAYTransactionSplit = createAsyncThunk(
  "ls/WIPAYTransactionSplit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getWIPAYTransSplit(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const WIPAYAggregatedSplit = createAsyncThunk(
  "ls/WIPAYAggregatedSplit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response =
        await ReportsServices.reportActions().getWIPAYAggregatedSplit(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getVFDReport = createAsyncThunk(
  "ls/getVFDReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ReportsServices.reportActions().getVfd(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getLUXReport = createAsyncThunk(
  "ls/getLUXReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getLux(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getGruppReport = createAsyncThunk(
  "ls/getLUXReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getGrupp(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getFincraReport = createAsyncThunk(
  "ls/getLUXReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getFincra(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getFincraReportCashout = createAsyncThunk(
  "ls/getLUXReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getFincraCashout(
        value
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getPayStackReport = createAsyncThunk(
  "ls/getPayStackReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getPayStack(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getUPAYReport = createAsyncThunk(
  "ls/getUPAYReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ReportsServices.reportActions().getUpay(value);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const retryAUPAYReport = createAsyncThunk(
  "ls/getAUPAYReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportActions().retryUPAY(payload);
      if (response) {
        dispatch(getUPAYReport());
        SuccessNotification(response?.data);
      }
      return response?.data;
    } catch (error) {
      ErrorNotification(error?.response?.data);
      exitReponse(error?.response);
      return error?.response;
    }
  }
);

export const createService = createAsyncThunk(
  "ls/createService",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().createService(
        value
      );
      scrollToTop();
      return response;
    } catch (error) {
      return error?.response;
    }
  }
);

const initialState = {
  auth: {},
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: {
    //Fetch Transactions
    //Eco Bank
    [ECOBankTransactions.pending]: (state) => {
      state.loading = true;
    },
    [ECOBankTransactions.fulfilled]: (state, action) => {
      return { ...state, ecobank: action.payload, loading: false };
    },
    [ECOBankTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [ECOBankUploadTransactions.pending]: (state) => {
      state.loading = true;
    },
    [ECOBankUploadTransactions.fulfilled]: (state, action) => {
      return { ...state, ecobank_upload: action.payload, loading: false };
    },
    [ECOBankUploadTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //vfd
    [getVFDReport.pending]: (state) => {
      state.loading = true;
    },
    [getVFDReport.fulfilled]: (state, action) => {
      return { ...state, vfd: action.payload, loading: false };
    },
    [getVFDReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //LUX
    [getLUXReport.pending]: (state) => {
      state.loading = true;
    },
    [getLUXReport.fulfilled]: (state, action) => {
      return { ...state, lux: action.payload, loading: false };
    },
    [getLUXReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //UPAY
    [getUPAYReport.pending]: (state) => {
      state.loading = true;
    },
    [getUPAYReport.fulfilled]: (state, action) => {
      return { ...state, upay: action.payload, loading: false };
    },
    [getUPAYReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [retryAUPAYReport.pending]: (state) => {
      state.loading = true;
    },
    [retryAUPAYReport.fulfilled]: (state, action) => {
      return { ...state, retryAUpayReport: action.payload, loading: false };
    },
    [retryAUPAYReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Paystack
    [getPayStackReport.pending]: (state) => {
      state.loading = true;
    },
    [getPayStackReport.fulfilled]: (state, action) => {
      return { ...state, paystack: action.payload, loading: false };
    },
    [getPayStackReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //WIPAY
    [WIPAYTransactionRequest.pending]: (state) => {
      state.loading = true;
    },
    [WIPAYTransactionRequest.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_request: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [WIPAYTransactionNotification.pending]: (state) => {
      state.loading = true;
    },
    [WIPAYTransactionNotification.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_notification: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [WIPAYTransactionSplit.pending]: (state) => {
      state.loading = true;
    },
    [WIPAYTransactionSplit.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_transaction_split: action.payload,
        loading: false,
      };
    },
    [WIPAYTransactionSplit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [WIPAYAggregatedSplit.pending]: (state) => {
      state.loading = true;
    },
    [WIPAYAggregatedSplit.fulfilled]: (state, action) => {
      return {
        ...state,
        wipay_aggregated_split: action.payload,
        loading: false,
      };
    },
    [WIPAYAggregatedSplit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Fetch Transactions
    [createService.pending]: (state) => {
      state.loading = true;
    },
    [createService.fulfilled]: (state, action) => {
      return { ...state, createService: action.payload, loading: false };
    },
    [createService.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default notificationSlice.reducer;
