import { formApi } from "../../redux/api";
import api from "../api";
import { apiToken } from "../api-helpers";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const reportActions = () => {
  return {
    getECOBANKTransactions: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/ecobank/report?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`
      );
    },
    getECOBANKUploadTransactions: function (data) {
      return api.post(`posnotifications/Admin/ecobank/notification`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + apiToken?.accessToken,
        },
      });
    },
    getWIPAYTransReq: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getWIPAYTransNot: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/notifications?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getWIPAYTransSplit: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactionsplits?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getWIPAYAggregatedSplit: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/transactionsplits/aggregate?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getVfd: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;

      return api.get(
        `posnotifications/Admin/vfd/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getLux: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;
      return api.get(
        `posnotifications/Admin/lux/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },

    getGrupp: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize, type } =
        data;
      return api.get(
        `posnotifications/Admin/grupp/transactions?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${
          searchText || ""
        }&startDate=${startDate}&endDate=${endDate}&type=${type}`,
        {
          headers: headers,
        }
      );
    },

    getFincra: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;
      return api.get(
        `posnotifications/Admin/fincra/transactions?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${
          searchText || ""
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },
    getFincraCashout: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;
      return api.get(
        `posnotifications/Admin/fincra/cashout/transactions?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${
          searchText || ""
        }&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
        }
      );
    },
    getPayStack: function (data) {
      const {
        currentPage,
        searchText,
        startDate,
        endDate,
        provider,
        pageSize,
      } = data;
      return api.get(
        `posnotifications/Admin/paystack/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}&Provider=${provider || ""}`,
        {
          headers: headers,
        }
      );
    },

    retryUPAY: function (data) {
      return api.post(`posnotifications/Admin/upay/notification/retry`, data, {
        headers: headers,
      });
    },

    getUpay: function (data) {
      const {
        currentPage,
        searchText,
        startDate,
        endDate,
        provider,
        pageSize,
      } = data;
      return api.get(
        `posnotifications/Admin/upay/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}&Provider=${provider}`,
        {
          headers: headers,
        }
      );
    },
    // Create Product

    getDataTransactions: function (data) {
      const { page, search, startDate, endDate, status, pageSize } = data;
      return api.get(
        `data/Admin/datatransactions?currentPage=${
          page === undefined ? 1 : page
        }&pageSize=${
          pageSize === undefined ? 10 : pageSize
        }&SearchText=${search}&StartDate=${startDate}&EndDate=${endDate}&TransactionStatus=${
          status === undefined ? 1 : status
        }`,
        {
          headers: headers,
        }
      );
    },

    getAirtimeTransactions: function (data) {
      const { page, search, startDate, endDate, status, pageSize } = data;
      return api.get(
        `airtime/Admin/airtimetransactions?currentPage=${
          page === undefined ? 1 : page
        }&pageSize=${
          pageSize === undefined ? 10 : pageSize
        }&SearchText=${search}&StartDate=${startDate}&EndDate=${endDate}&TransactionStatus=${
          status === undefined ? 1 : status
        }`,
        {
          headers: headers,
        }
      );
    },

    getWalletTransactions: function (data) {
      const {
        page,
        transactionId,
        startDate,
        endDate,
        status,
        pageSize,
        walletId,
        accountCode,
        accountType,
      } = data;
      return api.get(
        `wallet/Admin/settlementtransactions?currentPage=${
          page === undefined ? 1 : page
        }&transactionId=${transactionId}&walletId=${walletId}&accountCode=${accountCode}&accountType=${accountType}&startDate=${startDate}&endDate=${endDate}&entryType=${
          status === undefined ? 1 : status
        }&pageSize=${pageSize === undefined ? 15 : pageSize}`,
        {
          headers: headers,
        }
      );
    },
    getTransfersTransactions: function (data) {
      const { page, search, startDate, endDate, status, pageSize } = data;
      return api.get(
        `wallet/Admin/wallettransfers?currentPage=${
          page === undefined ? 1 : page
        }&pageSize=${
          pageSize === undefined ? 10 : pageSize
        }&searchText=${search}&startDate=${startDate}&endDate=${endDate}&TransactionStatus=${
          status === undefined ? 1 : status
        }`,
        {
          headers: headers,
        }
      );
    },

    getBillsTransactions: function (data) {
      const { currentPage, searchText, startDate, endDate, status, pageSize } =
        data;
      return api.get(
        `bills/Admin/billstransactions?currentPage=${currentPage}&pageSize=${
          pageSize === undefined ? 10 : pageSize
        }&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}&TransactionStatus=${
          status === undefined ? 1 : status
        }`,
        {
          headers: headers,
        }
      );
    },
    getAgents: function (data) {
      const { currentPage, searchText, startDate, endDate, provider } = data;
      return api.get(
        `posnotifications/Admin/pos/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=10&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}&Provider=${provider}`,
        {
          headers: headers,
        }
      );
    },

    getProvider: function (data) {
      const {
        currentPage,
        searchText,
        startDate,
        endDate,
        provider,
        pageSize,
      } = data;

      localStorage.setItem("Item", headers);
      return api.get(
        `posnotifications/Admin/pos/transactions?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}&Provider=${provider}`,
        {
          headers: headers,
        }
      );
    },
  };
};

export const ReportsServices = {
  reportActions: reportActions,
};
