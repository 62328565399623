import { useContext } from "react";
import { AuthContext } from "../context/Auth/AuthContext";

export function Permission(KEY) {
  const { permissions } = useContext(AuthContext);

  const filter = permissions?.map((code) => {
    return code?.permissionCode;
  })?.includes(KEY);

  return filter;
}





export function PermissionRoutes(KEY) {
  const { permissions } = useContext(AuthContext);

  const filter = permissions?.map((code) => {
    return code?.permissionGroupId;
  })?.includes(KEY);

  return filter;
}



