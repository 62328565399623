import axios from "axios";
import jwtDecode from "jwt-decode";
// import jwtDecode from "jwt-decode";
import { apiToken } from "./api-helpers";
import dayjs from "dayjs";
import redirectUser from "../routes/Redirect";
import { HOME } from "../routes/Constant";

// console.log(jwtDecode(apiToken?.token))

const legacy_api = (data) => {
  return axios.create({
    baseURL: data?.baseURL || process.env.REACT_APP_BACKEND_URL,
    timeout: data?.timeout || "",
    headers: {
      "Content-Type": data?.contentType || "application/json",
      Authorization: `Bearer ${data?.token || apiToken?.accessToken}`,
    },
  });
};

//Various APIs
const api = legacy_api();
const reporting_api = legacy_api({
  baseURL: process.env.REACT_APP_BACKEND_URL_REPORTING,
  token: process.env.REACT_APP_BACKEND_TOKEN_REPORTING,
  timeout: 100000000000,
});
const formApi = legacy_api({
  "Content-Type": "multipart/form-data",
});

const apis = api || reporting_api || formApi;

apis?.interceptors?.request?.use(async (req) => {
  if (!apiToken) {
    apiToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
  }

  let user = jwtDecode(apiToken?.accessToken);
  let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

  if (isExpired) {
    localStorage.removeItem("token");
    localStorage?.removeItem("permission");
    redirectUser(HOME, 1);
  }

  return req;
});

export { reporting_api, formApi, api };

export default api;
