export const ReportTypes = {
  GET_DATA: "GET_DATA",
  GET_AIRTIME: "GET_AIRTIME",
  GET_WALLET: "GET_WALLET",
  GET_BILLS: "GET_BILLS",
  GET_TRANSFERS: "GET_TRANSFERS",
  GET_AGENT_REPORT: "GET_AGENT_REPORT",

  LOADING: "LOADING",
};
