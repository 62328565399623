import { ReportTypes } from "./types";

export const TerminalReducer = (state, action) => {
  switch (action.type) {
    case ReportTypes.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ReportTypes.GET_TERMINAL: {
      return {
        ...state,
        terminal: action.payload,
      };
    }

    case ReportTypes.GET_TERMINAL_TRANSACTIONS: {
      return {
        ...state,
        terminal_transactions: action.payload,
      };
    }

    case ReportTypes.GET_TERMINAL_TYPES: {
      return {
        ...state,
        terminalTypes: action.payload,
      };
    }

    case ReportTypes.GET_AGENTS: {
      return {
        ...state,
        agent: action.payload,
      };
    }

    case ReportTypes.GET_AN_AGENTS: {
      return {
        ...state,
        an_agent: action.payload,
      };
    }

    case ReportTypes.GET_A_TERMINAL: {
      return {
        ...state,
        aTerminal: action.payload,
      };
    }

    default:
      return state;
  }
};
