// import api from "../api";
import api from "../../redux/api";
import { apiToken } from "../api-helpers";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const supportURL = "identity/ProductAdmin/product";

const productActions = () => {
  return {
    // Create Product

    getProducts: function (data) {
      const { currentPage, searchText, pageSize, all } = data;
      return api.get(
        `identity/ProductAdmin/products?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize}&SearchText=${
          searchText === undefined ? "" : searchText
        }&all=${all || false}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getAProduct: function (data) {
      return api.get(
        `identity/ProductAdmin/productsbyid?ProductId=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },

    createProduct: function (data) {
      return api.post("identity/ProductAdmin/products", data, {
        headers: headers,
      });
    },

    activateProduct: function (data) {
      return api.post(`${supportURL}/activate`, data, {
        headers: headers,
      });
    },

    deactivateProduct: function (data) {
      return api.post(`${supportURL}/deactivate`, data, {
        headers: headers,
      });
    },

    updateProduct: function (data) {
      return api.post(`identity/ProductAdmin/products/updateproduct`, data, {
        headers: headers,
      });
    },

    getServices: function (data) {
      const { searchText, page } = data;
      return api.get(
        `identity/ProductAdmin/services?currentPage=${
          page === undefined ? 1 : page
        }&pageSize=10&SearchText=${searchText === undefined ? "" : searchText}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getAService: function (data) {
      return api.get(
        `${supportURL}/servicesbyid?ProductServiceId=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },

    createService: function (data) {
      return api.post(`identity/ProductAdmin/services`, data, {
        headers: headers,
      });
    },

    activateService: function (data) {
      return api.post(`${supportURL}/services/activate`, data, {
        headers: headers,
      });
    },

    deactivateService: function (data) {
      return api.post(`${supportURL}/services/deactivate`, data, {
        headers: headers,
      });
    },

    updateService: function (data) {
      return api.post(
        `identity/ProductAdmin/product/services/updateservice`,
        data,
        {
          headers: headers,
        }
      );
    },

    getProviders: function (data) {
      const { searchText, page } = data;
      return api.get(
        `identity/ProductAdmin/providers?currentPage=${
          page === undefined ? 1 : page
        }&pageSize=10&SearchText=${searchText === undefined ? "" : searchText}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getProvider: function (data) {
      return api.get(
        `/identity/ProductAdmin/providers/${data}?id=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },

    createProvider: function (data) {
      return api.post("identity/ProductAdmin/providers", data, {
        headers: headers,
      });
    },

    updateProvider: function (data) {
      return api.post("identity/ProductAdmin/providers/update", data, {
        headers: headers,
      });
    },

    enableProvider: function (data) {
      return api.post("identity/ProductAdmin/providers/activate", data, {
        headers: headers,
      });
    },

    disableProvider: function (data) {
      return api.post("identity/ProductAdmin/providers/deactivate", data, {
        headers: headers,
      });
    },

    editProviderProduct: function (data) {
      return api.post("identity/ProductAdmin/providers/updateproduct", data, {
        headers: headers,
      });
    },

    enableProviderProduct: function (data) {
      return api.post(
        "identity/ProductAdmin/providers/product/activate",
        data,
        {
          headers: headers,
        }
      );
    },

    disableProviderProduct: function (data) {
      return api.post(
        "identity/ProductAdmin/providers/product/deactivate",
        data,
        {
          headers: headers,
        }
      );
    },

    createProviderProducts: function (data) {
      return api.post("identity/ProductAdmin/providers/product", data, {
        headers: headers,
      });
    },
  };
};

export const ProductServices = {
  productActions: productActions,
};
