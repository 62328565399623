import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SuccessNotification } from "../../helpers/alerts";
import { DASHBOARD, HOME } from "../../routes/Constant";
import redirectUser from "../../routes/Redirect";
import { exitReponse } from "../api-helpers";
import { AuthServices } from "./AuthService";
import { findSearchParams } from "../../context/Auth/AuthContext";
// import { FinanceServices } from "./FinanceService";

export const LoginUser = createAsyncThunk(
  "ls/LoginUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthServices.auth().login(payload);
      localStorage.setItem(
        "token",
        JSON.stringify(response?.data?.responseModel, null, 2)
      );
      redirectUser(findSearchParams().get("continue") || DASHBOARD, 1);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ResetPassword = createAsyncThunk(
  "ls/ResetPassword",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthServices.auth().resetpassword(payload);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const NewPassword = createAsyncThunk(
  "ls/ResetPassword",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await AuthServices.auth().setNewpassword(payload);
      SuccessNotification(response?.data?.message);
      redirectUser(HOME, 1);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

const initialState = {
  auth: "",
};

export const customersSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [LoginUser.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [LoginUser.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    },
    [LoginUser.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [ResetPassword.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ResetPassword.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        user_actions: action.payload,
      };
    },
    [ResetPassword.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default customersSlice.reducer;
