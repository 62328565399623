import React, { createContext, useReducer } from "react";
import { SuccessNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { AuthTypes } from "../types";
import { TerminalReducer } from "./TerminalReducer";
import { TerminalServices } from "./TerminalServices";
import { ReportTypes } from "./types";

const initialState = {
  loading: false,
  error: false,

  aTerminal: {},
  agent: [],
  an_agent: {},
  terminal: [],
  terminalTypes: [],
  terminal_transactions: [],
};

export const TerminalContext = createContext(initialState);

export const TerminalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TerminalReducer, initialState);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  function getAllAgents() {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_AGENTS,
      payload: null,
    });
    TerminalServices.reportActions()
      .getAgents()
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_AGENTS,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  function getAnAgent(data) {
    // dispatch({
    //   type: ReportTypes.LOADING,
    //   payload: true,
    // });

    dispatch({
      type: ReportTypes.GET_AN_AGENTS,
      payload: null,
    });
    TerminalServices.reportActions()
      .getAgent(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_AN_AGENTS,
          payload: res?.data,
        });
        // dispatch({
        //   type: AuthTypes.LOADING,
        //   payload: false,
        // });
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  function getAllTerminals(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_TERMINAL,
      payload: null,
    });
    TerminalServices.reportActions()
      .getTerminals(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_TERMINAL,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getAllTerminalTransactions(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_TERMINAL_TRANSACTIONS,
      payload: null,
    });
    TerminalServices.reportActions()
      .getTerminalTransactions(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_TERMINAL_TRANSACTIONS,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function getOneTerminal(data) {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_A_TERMINAL,
      payload: null,
    });
    TerminalServices.reportActions()
      .getATerminal(data)
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_A_TERMINAL,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function assignTerminal(data) {
    TerminalServices.reportActions()
      .mapTerminal(data)
      .then((res) => {
        getAllTerminals({
          status: data?.status,
          currentPage: 1,
          searchText: "",
        });
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  function unAssignTerminal(data) {
    TerminalServices.reportActions()
      .unmapTerminal(data)
      .then((res) => {
        getAllTerminals({
          status: data?.status,
          currentPage: 1,
          searchText: "",
        });
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  async function createSingleTerminal(data) {
    await TerminalServices.reportActions()
      .postSingleTerminal(data)
      .then((res) => {
        const resp = getAllTerminals({
          status: "",
          currentPage: 1,
          searchText: "",
        });
        SuccessNotification(res?.data?.message);

        return resp;
      })
      .catch((err) => {
        exitReponse(err);
        return err;
      });
  }

  function createMultipleTerminals(data) {
    TerminalServices.reportActions()
      .postMultipleTerminal(data)
      .then((res) => {
        getAllTerminals();
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  //Terminal Types
  function getAllTerminalTypes() {
    dispatch({
      type: ReportTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: ReportTypes.GET_TERMINAL_TYPES,
      payload: null,
    });
    TerminalServices.reportActions()
      .getTerminalTypes()
      .then((res) => {
        dispatch({
          type: ReportTypes.GET_TERMINAL_TYPES,
          payload: res?.data,
        });

        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function postTerminalType(data) {
    TerminalServices.reportActions()
      .postATerminalType(data)
      .then((res) => {
        SuccessNotification(res.data.message);
        getAllTerminalTypes();
      })
      .catch((err) => exitReponse(err));
  }

  return (
    <TerminalContext.Provider
      value={{
        loading: state.loading,
        terminal: state.terminal,
        terminalTypes: state.terminalTypes,
        agent: state.agent,
        an_agent: state.an_agent,
        aTerminal: state.aTerminal,
        terminal_transactions: state.terminal_transactions,

        getAllTerminals,
        getAllTerminalTypes,
        getAllAgents,
        postTerminalType,
        createSingleTerminal,
        createMultipleTerminals,
        assignTerminal,
        unAssignTerminal,
        getOneTerminal,
        getAnAgent,
        getAllTerminalTransactions,
      }}
    >
      {children}
    </TerminalContext.Provider>
  );
};
