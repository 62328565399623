import { AuthTypes } from "../types";

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthTypes.USER: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case AuthTypes.EMAILCONFIRMATION: {
      return {
        ...state,
        emailConfirmation: action.payload,
      };
    }

    case AuthTypes.PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }

    default:
      return state;
  }
};
