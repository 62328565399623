import React from "react";
import Loader from "react-loader-spinner";
import "./_loader.scss";

export function PageLoader() {
  return (
    <div className="laoder_suspense">
      <Loader type="Oval" color="#3f1f6b" height={35} width={35} />
    </div>
  );
}

export function ItemLoader() {
  return (
    <div className="laoder_item">
      <Loader type="Oval" color="#3f1f6b" height={35} width={35} />
    </div>
  );
}

export function ModalLoader() {
  return (
    <div className="laoder_modal">
      <Loader type="Oval" color="#3f1f6b" height={35} width={35} />
    </div>
  );
}

export function FormLoader({color, height, width}) {
  return (
    <div className="loader_form">
      <Loader type="Oval" color={color || "#fff"} height={height || 13.5} width={ width || 13.5} />
    </div>
  );
}
