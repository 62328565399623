import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SuccessNotification, ErrorNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { ReportsServices } from "./ReportsService";


export const ChargeBack = createAsyncThunk(
  "ls/GetAllBalanceSheet",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().ChargeBack(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);




export const GetAllBalanceSheet = createAsyncThunk(
  "ls/GetAllBalanceSheet",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().GetAllBalanceSheet(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetCore = createAsyncThunk(
  "ls/GetCore",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().GetCoreReports(
        payload
      );

      const res = JSON.parse(response?.data);

      return res?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const RetryBills = createAsyncThunk(
  "ls/RetryBills",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().retryBills(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      ErrorNotification(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
);

export const RetryAirtime = createAsyncThunk(
  "ls/RetryBills",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().retryAirtime(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      ErrorNotification(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
);

export const RetryData = createAsyncThunk(
  "ls/RetryBills",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().retryData(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      ErrorNotification(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
);

export const RetryTransfers = createAsyncThunk(
  "ls/RetryBills",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().retryTransfer(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      ErrorNotification(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
);

export const TransactionDetailsValue = createAsyncThunk(
  "ls/TransactionDetailsValue",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getTransactionDetailsValues(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentInfoByWalletId = createAsyncThunk(
  "ls/AgentInfoByWalletId",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getAgentInfoByWalletId(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      // exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const TerminalReportThisDay = createAsyncThunk(
  "ls/TerminalReportThisDay",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getTerminalReportThisDay(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const LienTransactions = createAsyncThunk(
  "ls/LienTransactions",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getLienTransactions(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const DebitWallet = createAsyncThunk(
  "ls/DebitWallet",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().postReleaseFunds(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ReleaseFunds = createAsyncThunk(
  "ls/DebitWallet",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().postReleaseFunds(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const TerminalReportThisWeek = createAsyncThunk(
  "ls/STerminalReportThisWeek",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getTerminalReportThisWeek(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ServiceGraphReportWeek = createAsyncThunk(
  "ls/ServiceGraphReport",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getServiceGraphReportWeek(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ServiceGraphReport = createAsyncThunk(
  "ls/ServiceGraphReport",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().GetCoreReports(
        payload
      );

      const res = JSON.parse(response?.data);

      return res?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentSummary = createAsyncThunk(
  "ls/AgentSummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getAgentSummaryList(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentsAlways = createAsyncThunk(
  "ls/AgentsAlways",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getAgentsThisWeek(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentsThisDay = createAsyncThunk(
  "ls/AgentsThisDay",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getAgentsThisDay(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentsAllTime = createAsyncThunk(
  "ls/AgentsThisWeek",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getAgentsAllTime(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AgentsThisWeek = createAsyncThunk(
  "ls/AgentsThisWeek",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getAgentsThisWeek(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const TodayTerminalSummary = createAsyncThunk(
  "ls/TodayTerminalSummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getTerminalSummaryList(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AppSummary = createAsyncThunk(
  "ls/AppSummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getTodaySummary(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const TodaySumarry = createAsyncThunk(
  "ls/TodaySumarry",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getTodaySummary(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ThisDaySummary = createAsyncThunk(
  "ls/ThiDaySummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsServices.reportsActions().getThisDaySummary(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ThisWeekSummary = createAsyncThunk(
  "ls/ThisWeekSummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getThisWeekSummary(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const LastWeekSummary = createAsyncThunk(
  "ls/LastWeekSummary",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getLastWeekSummary(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ProviderWallets = createAsyncThunk(
  "ls/ProviderWallets",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getProviderWallets(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CustomerWallets = createAsyncThunk(
  "ls/CustomerWallets",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getCustomerWallets(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CustomerWalletsBalance = createAsyncThunk(
  "ls/CustomerWalletsBalance",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getCustomerWalletsBalance(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ProviderWalletsBalance = createAsyncThunk(
  "ls/ProviderWalletsBalance",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getProviderWalletsBalance(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const TransactionListing = createAsyncThunk(
  "ls/TransactionListing",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsServices.reportsActions().getTransactionListing(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

const initialState = {
  banks: {},
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: {
    [GetAllBalanceSheet.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetAllBalanceSheet.fulfilled]: (state, action) => {
      return {
        ...state,
        all_balance_sheet: action.payload,
        loading: false,
      };
    },
    [GetAllBalanceSheet.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [GetCore.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetCore.fulfilled]: (state, action) => {
      return {
        ...state,
        core: action.payload,
        loading: false,
      };
    },
    [GetCore.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [DebitWallet.pending]: (state) => {
      return {
        ...state,
        loadingAction: true,
      };
    },
    [DebitWallet.fulfilled]: (state, action) => {
      return {
        ...state,
        lienFundsAction: action.payload,
        loadingAction: false,
      };
    },
    [DebitWallet.rejected]: (state, action) => {
      return {
        ...state,
        loadingAction: false,
        error: action.payload,
      };
    },

    [LienTransactions.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [LienTransactions.fulfilled]: (state, action) => {
      return {
        ...state,
        lienTransactions: action.payload,
        loading: false,
      };
    },
    [LienTransactions.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [RetryBills.pending]: (state) => {
      return {
        ...state,
        loadingRetry: false,
      };
    },
    [RetryBills.fulfilled]: (state, action) => {
      return {
        ...state,
        value: action.payload,
        loadingRetry: false,
      };
    },
    [RetryBills.rejected]: (state, action) => {
      return {
        ...state,
        loadingRetry: false,
        error: action.payload,
      };
    },

    [TransactionDetailsValue.pending]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    [TransactionDetailsValue.fulfilled]: (state, action) => {
      return {
        ...state,
        transactionDetailValue: action.payload,
        loading: false,
      };
    },
    [TransactionDetailsValue.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentInfoByWalletId.pending]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    [AgentInfoByWalletId.fulfilled]: (state, action) => {
      return {
        ...state,
        agentInfoByWalletId: action.payload,
        loading: false,
      };
    },
    [AgentInfoByWalletId.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [TerminalReportThisDay.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TerminalReportThisDay.fulfilled]: (state, action) => {
      return {
        ...state,
        terminalReportThisDay: action.payload,
        loading: false,
      };
    },
    [TerminalReportThisDay.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [TerminalReportThisWeek.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TerminalReportThisWeek.fulfilled]: (state, action) => {
      return {
        ...state,
        terminalReportThisWeek: action.payload,
        loading: false,
      };
    },
    [TerminalReportThisWeek.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [ServiceGraphReport.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ServiceGraphReport.fulfilled]: (state, action) => {
      return {
        ...state,
        serviceGraphReport: action.payload,
        loading: false,
      };
    },
    [ServiceGraphReport.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentsAlways.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentsAlways.fulfilled]: (state, action) => {
      return {
        ...state,
        agentsAlways: action.payload,
        loading: false,
      };
    },
    [AgentsAlways.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentsThisDay.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentsThisDay.fulfilled]: (state, action) => {
      return {
        ...state,
        agentsThisDay: action.payload,
        loading: false,
      };
    },
    [AgentsThisDay.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentsThisWeek.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentsThisWeek.fulfilled]: (state, action) => {
      return {
        ...state,
        AgentsThisWeek: action.payload,
        loading: false,
      };
    },
    [AgentsThisWeek.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentSummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentSummary.fulfilled]: (state, action) => {
      return {
        ...state,
        todayAgentSummary: action.payload,
        loading: false,
      };
    },
    [AgentSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TodayTerminalSummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TodayTerminalSummary.fulfilled]: (state, action) => {
      return {
        ...state,
        todayTerminalSummary: action.payload,
        loading: false,
      };
    },
    [TodayTerminalSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [TodaySumarry.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TodaySumarry.fulfilled]: (state, action) => {
      return {
        ...state,
        todaySummary: action.payload,
        loading: false,
      };
    },
    [TodaySumarry.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ThisDaySummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ThisDaySummary.fulfilled]: (state, action) => {
      return {
        ...state,
        thisDaySummary: action.payload,
        loading: false,
      };
    },
    [ThisDaySummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ThisWeekSummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ThisWeekSummary.fulfilled]: (state, action) => {
      return {
        ...state,
        thisWeekSummary: action.payload,
        loading: false,
      };
    },
    [ThisWeekSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TodaySumarry.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TodaySumarry.fulfilled]: (state, action) => {
      return {
        ...state,
        todaySummary: action.payload,
        loading: false,
      };
    },
    [TodaySumarry.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [ProviderWallets.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ProviderWallets.fulfilled]: (state, action) => {
      return {
        ...state,
        providerWallet: action.payload,
        loading: false,
      };
    },
    [ProviderWallets.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ProviderWalletsBalance.pending]: (state) => {
      return {
        ...state,
        loadingBalance: true,
      };
    },
    [ProviderWalletsBalance.fulfilled]: (state, action) => {
      return {
        ...state,
        providerWalletBalance: action.payload,
        loadingBalance: false,
      };
    },
    [ProviderWalletsBalance.rejected]: (state, action) => {
      return {
        ...state,
        loadingBalance: false,
        error: action.payload,
      };
    },

    [CustomerWalletsBalance.pending]: (state) => {
      return {
        ...state,
        loadingBalance: true,
      };
    },
    [CustomerWalletsBalance.fulfilled]: (state, action) => {
      return {
        ...state,
        customerWalletBalance: action.payload,
        loadingBalance: false,
      };
    },
    [CustomerWalletsBalance.rejected]: (state, action) => {
      return {
        ...state,
        loadingBalance: false,
        error: action.payload,
      };
    },

    [CustomerWallets.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CustomerWallets.fulfilled]: (state, action) => {
      return {
        ...state,
        customerWallet: action.payload,
        loading: false,
      };
    },
    [CustomerWallets.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TransactionListing.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TransactionListing.fulfilled]: (state, action) => {
      return {
        ...state,
        transactionListing: action.payload,
        loading: false,
      };
    },
    [TransactionListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default reportsSlice.reducer;
