import api from "../../context/api";


const headersForLogin = {
  "Content-Type": "application/json",
};


const supportURL = "identity/Admin/users";

const auth = () => {
  return {
    login: function (data) {
      return api.post(`${supportURL}/login`, data, {
        headers: headersForLogin,
      });
    },
    resetpassword: function (data) {
      return api.post(`${supportURL}/resetpassword`, data, {
        headers: headersForLogin,
      });
    },
    setNewpassword: function (data) {
      return api.post(`${supportURL}/setuserpassword`, data, {
        headers: headersForLogin,
      });
    },
  };
};



const permissions = () => {
  return {
    myPermissions: function () {
      return api.get("identity/Admin/mypermissions");
    },
  };
};

export const AuthServices = {
  auth: auth,
  permissions: permissions,
};
