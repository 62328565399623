import {
  HiClipboardCheck,
  HiDatabase,
  HiDocumentReport,
  HiHome,
  HiLibrary,
  HiPresentationChartBar,
  HiTerminal,
  HiUserAdd,
  HiUserGroup,
} from "react-icons/hi";
import {
  BULK_UPLOAD_AGENT,
  BULK_UPLOAD_RESELLER,
  MANUAL_FUNDING,
  PERMITTED_ROUTES,
  VIEW_AGENT,
  CUSTOMER_LISTING as VIEW_CUSTOMER_WALLET,
  VIEW_REPORT_SETTLEMENT,
  VIEW_RESELLER,
  VIEW_SUPERDEALER,
  VIEW_SYSTEM_USERS,
  REVERSAL as VIEW_REVERSAL,
  VIEW_TRANSACTION_CHARGES,
  VIEW_TRANSACTION_LIMIT,
  VIEW_PROVIDERS,
  VIEW_CUSTOMER_ACCOUNT_CODE,
  VIEW_BANK,
  AGENT_LISTING,
  TERMINAL_REPORT_LISTING,
  TRADING_LISTING,
  PROVIDER_WALLET_LISTING,
  VIEW_BALANCE_SHEET,
  ACCESS_BANK_REPORT,
  // PROVIDER_LISTING,
  // VIEW_PROVIDER_BALANCE,
  // VIEW_REPORT_TRANSFER,
  // VIEW_REPORT_DATA,
  // VIEW_REPORT_AIRTIME,
  // VIEW_REPORT_BILLS,
  // VIEW_NOTIFICATIONS_UPAY,
  // VIEW_NOTIFICATIONS_VFD,
  // VIEW_NOTIFICATIONS_LUX,
  // VIEW_NOTIFICATIONS_PAYSTACK,
} from "../../permissions/permission_key";
import {
  ACCOUNTCODE,
  AIRTIME,
  AUDIT,
  BALANCE_SHEET,
  // BALANCE_SHEET,
  BANKING,
  // BANKING_TRAMSACTION,
  BILLS,
  BULK_UPLOADS,
  CHANNELS,
  CHARGES,
  CHARGE_BACKS,
  CUSTOMERS_AGENTS,
  CUSTOMERS_RESELLERS,
  CUSTOMERS_SUPER_DEALERS,
  CUSTOMERS_TELLERS,
  CUSTOMER_CASHFLOW,
  CUSTOMER_WALLET,
  DASHBOARDX,
  // DATA,
  DATA_RECHARGE,
  ECOBANK,
  FINANCE,
  LIEN_TRANSACTIONS,
  LUX,
  MAPPING,
  NB_CHARGES,
  NOTIFICATION,
  OTHER_REPORTS,
  PROCESSOR_SWITCH,
  PROVIDER_PAGE,
  PROVIDER_RESPONSE,
  PROVIDER_WALLET,
  REPORTS,
  REVERSAL,
  ROLES,
  SERVICES,
  SETTLEMENT,
  // SETTLEMENT,
  TERMINAL_WALLET,
  TRADING_BREAKDOWN,
  TRANSACTIONLIMIT,
  TRANSACTION_LISTING,
  TRANSFERS,
  USERS,
  VFD,
  VFD_RETRIGGER,
  WALLET_SETTLEMENT,
  WIPAY,
} from "../../routes/Constant";

export const SideModules = [
  {
    iconIMG: <HiHome />,
    iconAlt: "I",
    iconTag: "Dashboard",
    iconLink: DASHBOARDX,
    linkEnd: true,
    linkPermissions: PERMITTED_ROUTES.DASHBOARD,
  },
  {
    iconIMG: <HiUserGroup />,
    iconAlt: "I",
    iconTag: "Users",
    iconLink: USERS,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.USERS,
    subLinks: [
      {
        sublink: "System Users",
        subUrl: USERS,
        linkPermissions: VIEW_SYSTEM_USERS,
      },
      {
        sublink: "Super Dealer",
        subUrl: USERS + "/" + CUSTOMERS_SUPER_DEALERS,
        linkPermissions: VIEW_SUPERDEALER,
      },
      {
        sublink: "Reseller",
        subUrl: USERS + "/" + CUSTOMERS_RESELLERS,
        linkPermissions: VIEW_RESELLER,
      },
      {
        sublink: "Agent",
        subUrl: USERS + "/" + CUSTOMERS_AGENTS,
        linkPermissions: VIEW_AGENT,
      },
      {
        sublink: "Teller",
        subUrl: USERS + "/" + CUSTOMERS_TELLERS,
        linkPermissions: VIEW_AGENT,
      },
      {
        sublink: "Bulk Uploads",
        subUrl: USERS + "/" + BULK_UPLOADS,
        linkPermissions: BULK_UPLOAD_AGENT || BULK_UPLOAD_RESELLER,
      },
      {
        sublink: "VFD Retrigger",
        subUrl: USERS + "/" + VFD_RETRIGGER,
        linkPermissions: VIEW_AGENT,
        // linkPermissions: VIEW_PROVIDER_BALANCE,
      },
      //  {
      //    sublink: "Marker Checker",
      //    subUrl: USERS + "/" + DATA,
      //    linkPermissions: VIEW_REPORT_DATA,
      //  },
    ],
  },
  {
    iconIMG: <HiUserAdd />,
    iconAlt: "I",
    iconTag: "Role Management",
    iconLink: ROLES,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.ROLES,
  },
  {
    iconIMG: <HiTerminal />,
    iconAlt: "I",
    iconTag: "Terminal Management",
    iconLink: MAPPING,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.TERMINAL,
  },
  {
    iconIMG: <HiClipboardCheck />,
    iconAlt: "I",
    iconTag: "Services/Products",
    iconLink: SERVICES,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.PRODUCTS_SERVICES,
  },
  //  {
  //    iconIMG: <HiCash />,
  //    iconAlt: "I",
  //    iconTag: "Payment Setup",
  //    iconLink: DASHBOARDX + "/payment-setup",
  //    linkEnd: false,
  //    linkPermissions: PERMITTED_ROUTES.TERMINAL,
  //  },
  {
    iconIMG: <HiLibrary />,
    iconAlt: "I",
    iconTag: "Finance",
    iconLink: FINANCE,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.FINANCE,
    subLinks: [
      {
        sublink: "Manual Funding",
        subUrl: FINANCE,
        linkPermissions: MANUAL_FUNDING,
      },
      {
        sublink: "Reversal",
        subUrl: FINANCE + "/" + REVERSAL,
        linkPermissions: VIEW_REVERSAL,
      },
      {
        sublink: "Invoice Charges",
        subUrl: FINANCE + "/" + NB_CHARGES,
        linkPermissions: VIEW_TRANSACTION_CHARGES,
      },
      {
        sublink: "Platform Charges",
        subUrl: FINANCE + "/" + CHARGES,
        linkPermissions: VIEW_TRANSACTION_CHARGES,
      },
      {
        sublink: "Transaction Limits",
        subUrl: FINANCE + "/" + TRANSACTIONLIMIT,
        linkPermissions: VIEW_TRANSACTION_LIMIT,
      },
      {
        sublink: "Provider",
        subUrl: FINANCE + "/provider",
        linkPermissions: VIEW_PROVIDERS,
      },
      {
        sublink: "Account Code",
        subUrl: FINANCE + "/" + ACCOUNTCODE,
        linkPermissions: VIEW_CUSTOMER_ACCOUNT_CODE,
      },
      {
        sublink: "Banking",
        subUrl: FINANCE + "/" + BANKING,
        linkPermissions: VIEW_BANK,
      },
      {
        sublink: "Channels",
        subUrl: FINANCE + "/" + CHANNELS,
        linkPermissions: VIEW_BANK,
      },
      {
        sublink: "Lien Transactions",
        subUrl: FINANCE + "/" + LIEN_TRANSACTIONS,
        linkPermissions: VIEW_BANK,
        // linkPermissions: VIEW_PROVIDER_BALANCE,
      },
      {
        sublink: "Processor Switch",
        subUrl: FINANCE + "/" + PROCESSOR_SWITCH,
        linkPermissions: VIEW_BANK,
      },
      {
        sublink: "Plan Update",
        subUrl: FINANCE + "/plan-update",
        linkPermissions: VIEW_BANK,
      },

      {
        sublink: "Charge Back",
        subUrl: FINANCE + "/" + CHARGE_BACKS,
        linkPermissions: VIEW_BANK,
      },
    ],
  },
  {
    iconIMG: <HiDocumentReport />,
    iconAlt: "I",
    iconTag: "Reports",
    iconLink: REPORTS,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.REPORT,
    subLinks: [
      {
        sublink: "Agent Listing",
        subUrl: REPORTS,
        linkPermissions: AGENT_LISTING,
      },
      {
        sublink: "Transaction Listing",
        subUrl: REPORTS + "/" + TRANSACTION_LISTING,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },
      {
        sublink: "Terminal Report",
        subUrl: REPORTS + "/" + TERMINAL_WALLET + "/today/status?status=Active",
        linkPermissions: TERMINAL_REPORT_LISTING,
      },
      {
        sublink: "Trading Breakdown",
        subUrl: REPORTS + "/" + TRADING_BREAKDOWN,
        linkPermissions: TRADING_LISTING,
      },
      {
        sublink: "Customer Wallet",
        subUrl: REPORTS + "/" + CUSTOMER_WALLET,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },
      {
        sublink: "Provider Wallet",
        subUrl: REPORTS + "/" + PROVIDER_WALLET,
        linkPermissions: PROVIDER_WALLET_LISTING,
      },
      // {
      //   sublink: "Settlement",
      //   subUrl: REPORTS + "/" + SETTLEMENT,
      //   linkPermissions: VIEW_CUSTOMER_WALLET,
      //   // linkPermissions: VIEW_REPORT_SETTLEMENT,
      // },
      {
        sublink: "Bills",
        subUrl: REPORTS + "/" + BILLS,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_REPORT_BILLS,
      },
      {
        sublink: "Airtime Recharge",
        subUrl: REPORTS + "/" + AIRTIME,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_REPORT_AIRTIME,
      },
      {
        sublink: "Data Recharge",
        subUrl: REPORTS + "/" + DATA_RECHARGE,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_REPORT_DATA,
      },
      {
        sublink: "Transfers",
        subUrl: REPORTS + "/" + TRANSFERS,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_REPORT_TRANSFER,
      },
      {
        sublink: "Provider Balance",
        subUrl: REPORTS + "/" + PROVIDER_PAGE,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_PROVIDER_BALANCE,
      },

      {
        sublink: "Bi",
        subUrl: REPORTS + "/bi",
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkEnd: false,
        // linkPermissions: VIEW_NOTIFICATIONS_VFD,
      },

      {
        sublink: "Provider Response",
        subUrl: REPORTS + "/" + PROVIDER_RESPONSE,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },
      {
        sublink: "Balance Sheet",
        subUrl: REPORTS + "/" + BALANCE_SHEET,
        linkPermissions: VIEW_BALANCE_SHEET,
      },
      {
        sublink: "Access Bank Report",
        subUrl: REPORTS + "/" + ACCESS_BANK_REPORT,
        // linkPermissions: TRADING_LISTING,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },

      // {
      //   sublink: "Customer Cashflow",
      //   subUrl: REPORTS + "/" + CUSTOMER_CASHFLOW,
      //   linkPermissions: VIEW_CUSTOMER_WALLET,
      //   // linkPermissions: VIEW_PROVIDER_BALANCE,
      // },

      // {
      //   sublink: "Provider Listing",
      //   subUrl: REPORTS + "/TERMINAL_WALLET",
      //   linkPermissions: PROVIDER_LISTING,
      // },
      // {
      //   sublink: "Banking Transactions",
      //   subUrl: REPORTS + "/" + BANKING_TRAMSACTION,
      //   linkPermissions: VIEW_BALANCE_SHEET,
      // },
    ],
  },
  {
    iconIMG: <HiDocumentReport />,
    iconAlt: "I",
    iconTag: "Banking/Payment",
    iconLink: NOTIFICATION,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.REPORT,
    // linkPermissions: PERMITTED_ROUTES.NOTIFICATION,
    subLinks: [
      {
        sublink: "Wallet Settlement",
        subUrl: NOTIFICATION,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkPermissions: VIEW_NOTIFICATIONS_UPAY,
      },
      {
        sublink: "Notification",
        subUrl: NOTIFICATION + "/" + WALLET_SETTLEMENT,
        linkPermissions: VIEW_CUSTOMER_WALLET,
        // linkEnd: false,
        // linkPermissions: VIEW_NOTIFICATIONS_VFD,
      },

      // {
      //   sublink: "LUX",
      //   subUrl: NOTIFICATION + "/" + LUX,
      //   linkPermissions: VIEW_CUSTOMER_WALLET,
      //   // linkPermissions: VIEW_NOTIFICATIONS_LUX,
      // },
      // {
      //   sublink: "PAYSTACK",
      //   subUrl: NOTIFICATION + "/" + PAYSTACK,
      //   linkPermissions: VIEW_CUSTOMER_WALLET,
      //   // linkPermissions: VIEW_NOTIFICATIONS_PAYSTACK,
      // },
      // {
      //   sublink: "UPAY",
      //   subUrl: NOTIFICATION + "/" + UPAY,
      //   linkPermissions: VIEW_CUSTOMER_WALLET,
      //   // linkPermissions: VIEW_NOTIFICATIONS_UPAY,
      // },
    ],
  },
  {
    iconIMG: <HiPresentationChartBar />,
    iconAlt: "I",
    iconTag: "Custom Report",
    iconLink: OTHER_REPORTS,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.CUSTOM_REPORT,
    subLinks: [
      {
        sublink: "WIPAY",
        subUrl: OTHER_REPORTS + "/" + WIPAY,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },
      {
        sublink: "Ecobank",
        subUrl: OTHER_REPORTS + "/" + ECOBANK,
        linkPermissions: VIEW_CUSTOMER_WALLET,
      },
    ],
  },
  {
    iconIMG: <HiDatabase />,
    iconAlt: "I",
    iconTag: "Audit Trail",
    iconLink: AUDIT,
    linkEnd: false,
    linkPermissions: PERMITTED_ROUTES.AUDIT,
  },
];
