import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./notificationSlice";
import productReducer from "./productSlice";
import customersReducer from "./Users/CustomerSlice";
import financeReducer from "./Finance/FinanceSlice";
import reportsReducer from "./Reports/ReportsSlice"
import authReducer from "./Auth/AuthSlice";
import terminalReducer from "./Terminal/TerminalSlice"




const store = configureStore({
  reducer: {
    app: productReducer,
    notification: notificationReducer,
    customers: customersReducer,
    finance: financeReducer,
    reports: reportsReducer,
    auth: authReducer,
    terminal: terminalReducer
  },
});

export default store;
