// import axiosInstance as api from "../api";
import api, { reporting_api } from "../api";
import { apiToken } from "../api-helpers";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const reportsActions = () => {
  return {
    ChargeBack: function (data) {
      const { chargeType } = data;
      return api.post(`${chargeType}/Admin/chargeback`, {
        ...data,
      });
    },
    GetAllBalanceSheet: function (data) {
      const { date, currentPage, pageSize } = data;
      return reporting_api.get(
        `/balance_sheet/all?page=${currentPage}&date=${date}&per_page=${pageSize}`,
        data
      );
    },
    GetCoreReports: function (data) {
      return api.post(`/wallet/Report`, data);
    },

    getTransactionDetailsValues: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      const { URLType, ref } = data;
      return api.get(
        `${
          URLType === "AIRTIME"
            ? URLType.toLowerCase()
            : URLType === "DATA"
            ? URLType.toLowerCase()
            : "bills"
        }/Admin/reports/${
          URLType === "AIRTIME"
            ? URLType.toLowerCase()
            : URLType === "DATA"
            ? URLType.toLowerCase()
            : "billspayment"
        }/${ref}?reference=${ref}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getLienTransactions: function (data) {
      const { currentPage, pageSize, status, search, walletId } = data;
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(
        `wallet/Transaction/liens?PageSize=${pageSize}&Statuses=${status}&SearchText=${search}&CurrentPage=${currentPage}&walletId=${walletId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    postDebitWallet: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.post(`wallet/Admin/complete_lien`, data, {
        headers: headers,
      });
    },

    postReleaseFunds: function (data) {
      const { URLType } = data;
      // console.log("u", URLType)
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.post(
        `${
          URLType === "Airtime"
            ? URLType.toLowerCase()
            : URLType === "Data"
            ? URLType.toLowerCase()
            : URLType === "TRANSFER"
            ? "wallet"
            : URLType === "TRANSFER"
            ? "wallet"
            : "bills"
        }/Admin/complete_lien`,
        data,
        {
          headers: headers,
        }
      );
    },

    //Dashboard Reports
    getServiceGraphReport: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`wallet/Transaction/report/service/today`, data, {
        headers: headers,
      });
    },

    getServiceGraphReportWeek: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`wallet/Transaction/report/service/thisweek`, data, {
        headers: headers,
      });
    },

    getAgentSummaryList: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`identity/AdminReports/agent/topten?count=${10}`, data, {
        headers: headers,
      });
    },

    getAgentAlways: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`identity`, data, {
        headers: headers,
      });
    },

    getAgentsThisDay: function (data) {
      const { currentPage, pageSize, status } = data;
      return api.get(
        `identity/AdminReports/agent/active/today?PageSize=${pageSize}&CurrentPage=${currentPage}&Type=${status}&AgentRole=${status.toUpperCase()}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getAgentInfoByWalletId: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`identity/AdminReports/agent/info/${data}`, data, {
        headers: headers,
      });
    },

    getAgentsAllTime: function (data) {
      const { currentPage, pageSize, status, searchText, walletId } = data;
      return api.get(
        `wallet/Transaction/report/summary/agent?PageSize=${pageSize}&AgentRole=${status}&SearchText=${searchText}&CurrentPage=${currentPage}&walletId=${walletId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getAgentsThisWeek: function (data) {
      const { currentPage, pageSize, status } = data;
      return api.get(
        `identity/AdminReports/agent/active/this_week?PageSize=${pageSize}&CurrentPage=${currentPage}&Type=${
          "this_week" || status.toUpperCase()
        }&AgentRole=${status.toUpperCase()}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getTerminalSummaryList: function (data) {
      return api.get(`posnotifications/Admin/terminals/summary`, data, {
        headers: headers,
      });
    },

    getTerminalReportThisDay: function (data) {
      const { type, serialNo, status, searchText, pageSize, currentPage } =
        data;
      return api.get(
        `posnotifications/api/TerminalReport/${type}?PageSize=${pageSize}&PageNumber=${currentPage}&SearchText=${searchText}&Status=${status}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getTerminalReportThisWeek: function (data) {
      const { pageSize, currentPage } = data;
      return api.get(
        `posnotifications/Admin/terminals/transactions/this_week?pageSize=${pageSize}&currentPage=${currentPage}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getTodaySummary: function (data) {
      return api.get(
        `wallet/Transaction/report/summary?currentPage=1&pageSize=15`,
        data,
        {
          headers: headers,
        }
      );
    },

    getThisDaySummary: function (data) {
      const {
        currentPage,
        searchText,
        startDate,
        endDate,
        pageSize,
        transRef,
        walletId,
        status,
      } = data;
      return api.get(
        `wallet/Transaction/report/summary/today?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${searchText || ""}&startDate=${
          startDate || ""
        }&endDate=${endDate || ""}&walletId=${
          walletId || ""
        }&transactionReference=${transRef || ""}&status=${status || ""}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getThisWeekSummary: function (data) {
      const {
        currentPage,
        searchText,
        startDate,
        endDate,
        pageSize,
        transRef,
        walletId,
        status,
      } = data;
      return api.get(
        `wallet/Transaction/report/summary/this_week?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${searchText || ""}&startDate=${
          startDate || ""
        }&endDate=${endDate || ""}&walletId=${
          walletId || ""
        }&transactionReference=${transRef || ""}&status=${status || ""}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getLastWeekSummary: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(
        `wallet/Transaction/report/summary/last_week?currentPage=1&pageSize=15`,
        data,
        {
          headers: headers,
        }
      );
    },

    getProviderWallets: function (data) {
      const { currentPage, search, startDate, endDate, pageSize } = data;
      // console.log(search);
      return api.get(
        `wallet/Transaction/provider_wallets?currentPage=${
          currentPage || 1
        }&pageSize=${
          pageSize === undefined ? 10 : pageSize
        }&searchText=${search}&startDate=${startDate}&endDate=${endDate}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getCustomerWallets: function (data) {
      const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(
        `wallet/Transaction/customer_wallets?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&WalletId=${
          search === undefined ? "" : search
        }&startDate=${startDate}&endDate=${endDate}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getCustomerWalletsBalance: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;
      return api.get(
        `wallet/Transaction/customer_opening_balances?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getProviderWalletsBalance: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize } = data;
      return api.get(
        `wallet/Transaction/provider_opening_balances?currentPage=${
          currentPage === undefined ? 1 : currentPage
        }&pageSize=${pageSize === undefined ? 10 : pageSize}&searchText=${
          searchText === undefined ? "" : searchText
        }&startDate=${startDate}&endDate=${endDate}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getTransactionListing: function (data) {
      const { currentPage, searchText, startDate, endDate, pageSize, status } =
        data;
      return api.get(
        `wallet/Transaction/transactions?currentPage=${
          currentPage || 1
        }&pageSize=${pageSize || 10}&searchText=${searchText || ""}&startDate=${
          startDate || ""
        }&endDate=${endDate || ""}&status=${status || ""}`,
        data,
        {
          headers: headers,
        }
      );
    },

    retryBills: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.post(
        `bills/Admin/requery`,
        { transactionReference: data },
        {
          headers: headers,
        }
      );
    },

    retryAirtime: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(
        `airtime/Airtime/transactionquery?TransactionReference=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },

    retryData: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(
        `identity/AdminReports/agent/active/this_week?TransactionReference=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },
    retryTransfer: function (data) {
      // const { currentPage, search, startDate, endDate, pageSize } = data;
      return api.get(`identity/AdminReports/agent/active/this_week`, data, {
        headers: headers,
      });
    },
  };
};

export const ReportsServices = {
  reportsActions: reportsActions,
};
