import React, { useContext } from "react";
import { AuthContext } from "../../../utils/context/Auth/AuthContext";
import Denial from "../../../utils/permissions/DenialPage/Denial";
import "./style/style.css";

function DashboardTemplate({ children, title, cta, url, action, permission }) {
  const { permissions } = useContext(AuthContext);

  // console.log("p",permissions);

  const filter = permissions
    ?.map((code) => {
      return code?.permissionCode;
    })
    ?.includes(permission);

  if (filter === false || filter === undefined) {
    return <Denial />;
  }

  return (
    <div className="template">
      <div
        className="dashboard_page_details "
        style={{
          alignItems: action ? "unset" : "center",
          marginBottom: action ? "-110px" : "0px",
        }}
      >
        <div className="location">
          {action}

          <b>{title}</b>
        </div>

        <div className="cta_button">{cta}</div>
      </div>
      <div className="db_items page_width">{children}</div>
    </div>
  );
}

export default DashboardTemplate;
