import React, { createContext, useReducer } from "react";
import { ErrorNotification, SuccessNotification } from "../../helpers/alerts";
import { DASHBOARD, HOME } from "../../routes/Constant";
import redirectUser from "../../routes/Redirect";
import { exitReponse } from "../api-helpers";
import { AuthTypes } from "../types";
import { AuthReducer } from "./AuthReducer";
import { AuthServices } from "./AuthServices";

const initialState = {
  token: localStorage?.getItem("token")
    ? JSON.parse(localStorage?.getItem("token"))
    : null || [],

  loading: true,

  emailConfirmation: [],

  permissions: localStorage?.getItem("permission")
    ? JSON.parse(localStorage?.getItem("permission"))
    : null,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //Login Function
  function storeToken(data) {
    AuthServices.auth()
      .login(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.USER,
          payload: res?.data?.responseModel,
        });
        localStorage.setItem(
          "token",
          JSON.stringify(res?.data?.responseModel, null, 2)
        );
        redirectUser(DASHBOARD, 1);
      })
      .catch((err) => ErrorNotification(err?.response?.data?.message));
    // redirectUser(DASHBOARD, 2250);
  }

  //Reset Password Fucntion
  function resetPassword(data) {
    AuthServices.auth()
      .resetpassword(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.EMAILCONFIRMATION,
          payload: res?.data,
        });
        SuccessNotification(res?.data?.message);
        setTimeout(() => {
          SuccessNotification("Redirecting to Home...");
        }, 2500);
        setTimeout(() => {
          redirectUser(HOME, 0);
        }, 5000);
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.message);
      });
    // redirectUser(DASHBOARD, 2250);
  }

  //Create New Password Function
  function setNewPassword(data) {
    AuthServices.auth()
      .setNewpassword(data)
      .then((res) => {
        SuccessNotification(res?.data?.message);
        redirectUser(HOME, 1);
      })
      .catch((err) => ErrorNotification(err?.response?.data?.message));
    // redirectUser(DASHBOARD, 2250);
  }

  //Log Out Function
  function clearToken(data) {
    const { searchParams } = data;
    localStorage.removeItem("token");
    localStorage?.removeItem("permission");
    if (typeof searchParams !== undefined) {
      return redirectUser(`${HOME}?continue=${searchParams}`, 1);
    } else return redirectUser(`${HOME}`, 1);
  }

  //Get My Permissions
  function myPermissions() {
    AuthServices.permissions()
      .myPermissions()
      .then((res) => {
        dispatch({
          type: AuthTypes.PERMISSIONS,
          payload: res?.data?.responseModel?.rolePermissions,
        });

        if (res?.data?.responseModel === null) {
          // clearToken();
          return;
        } else
          localStorage.setItem(
            "permission",
            JSON.stringify(res?.data?.responseModel?.rolePermissions, null, 2)
          );

        // ||
        //   localStorage.setItem(
        //     "permission",
        //     localStorage.getItem("permission")
        //   );
      })
      .catch((err) => {
        exitReponse(err);
      });
  }

  // useEffect(() => {
  //   myPermissions();
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        storeToken,
        clearToken,
        resetPassword,
        setNewPassword,
        token: state.token,
        emailConfirmation: state.emailConfirmation,
        permissions: state.permissions,
        myPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const findSearchParams = () => {
  const search = window.location?.search;
  const continueRoute = new URLSearchParams(search);

  return continueRoute;
};
