import axios from "axios";
import jwtDecode from "jwt-decode";
// import jwtDecode from "jwt-decode";
import { apiToken } from "./api-helpers";
import dayjs from "dayjs";
import redirectUser from "../routes/Redirect";
import { HOME } from "../routes/Constant";

// console.log(jwtDecode(apiToken?.token))

const invapi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL_INV,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

invapi?.interceptors?.request?.use(async (req) => {
  if (!apiToken) {
    apiToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
  }

  let user = jwtDecode(apiToken?.accessToken);
  let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

  if (isExpired) {
    localStorage.removeItem("token");
    localStorage?.removeItem("permission");
    redirectUser(HOME, 1);
  }

  return req;
});

export default invapi;
