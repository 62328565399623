import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function SuccessNotification(message) {
  const success = toast.success(message);

  return success;
}

export function ErrorNotification(message) {
  const error = toast.error(message, { hideProgressBar: true });

  return error;
}
