import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { LoggedInRoute, ProtectedRoutes } from "./utils/routes/ProtectedRoutes";

//Routes
import {
  CREATE,
  ERROR,
  HOME,
  DASHBOARD,
  SETPASSWORD,
  FORGOTPASSWORD,
  USERS,
  DASHERROR,
  ROLES,
  AUDIT,
  PRODUCTS,
  CATEGORY,
  SERVICES,
  CREATESERVICES,
  REPORTS,
  MAPPING,
  TERMINAL_TYPES,
  // CUSTOMERS_AGENTS,
  CUSTOMERS_RESELLERS,
  CUSTOMERS_SUPER_DEALERS,
  CUSTOMERS_CREATE_SUPER_DEALERS,
  BULK_UPLOADS,
  CUSTOMERS_CREATE_RESELLERS,
  CUSTOMERS_AGENTS,
  CUSTOMERS_CREATE_AGENT,
  FINANCE,
  BANKING,
  OTHER_REPORTS,
  ACCOUNTCODE,
  TRANSACTIONLIMIT,
  PROVIDER,
  ADD_PROVIDER,
  ADD_PROVIDER_PRPDUCTS,
  CUSTOMER_WALLET,
  TERMINAL_WALLET,
  REVERSAL,
  CHARGES,
  CHARGES_ACTION,
  PROVIDER_DETAILS,
  TRANSACTION_LISTING,
  TRADING_BREAKDOWN,
  BANKING_TRAMSACTION,
  BALANCE_SHEET,
  PROVIDER_WALLET,
  AIRTIME,
  DATA_RECHARGE,
  BILLS,
  TRANSFERS,
  NOTIFICATION,
  VFD,
  LUX,
  UPAY,
  PAYSTACK,
  PROVIDER_PAGE,
  LIEN_TRANSACTIONS,
  NB_CHARGES,
  NB_CHARGES_ACTION,
  CUSTOMERS_CREATE_CORPRATE_AGENT_UPLOAD,
  CUSTOMERS_CREATE_CORPRATE_AGENT,
  VFD_RETRIGGER,
  CUSTOMER_CASHFLOW,
  SETTLEMENT,
  WALLET_SETTLEMENT,
  FINCRA,
  GRUPP,
  PROCESSOR_SWITCH,
  PROVIDER_RESPONSE,
  CHARGE_BACKS,
  CUSTOMERS_TELLERS,
  CHANNELS,
  WIPAY,
  ECOBANK

} from "./utils/routes/Constant";

import { PageLoader } from "./utils/loader/loader";
import DashboardTemplate from "./app/dashboard/templates/template";
import { TRADING_LISTING } from "./utils/permissions/permission_key";
// import SuperDealerTable from "./app/dashboard/pages/Finance/SuperDealer/SuperDealerTable";
// import { AddProviderModal } from "./app/dashboard/pages/Finance/AddProviderModal";

//Import Route Elements
const Login = lazy(() => import("./app/auth-routes/Login"));
const CreateAccount = lazy(() => import("./app/auth-routes/Create-Account"));
const Dashboard = lazy(() => import("./app/dashboard"));
const SetPassword = lazy(() => import("./app/auth-routes/PasswordOps"));
const ForgotPassword = lazy(() =>
  import("./app/auth-routes/PasswordOps/ForgotPassword")
);

//Dashboard Rputes
const DashboardHome = lazy(() => import("./app/dashboard/pages/Dashboard"));
const Users = lazy(() => import("./app/dashboard/pages/Users/Users"));
const UsersHome = lazy(() => import("./app/dashboard/pages/Users/UserHome"));
const UserItem = lazy(() => import("./app/dashboard/pages/Users/UserItem"));
const BulkUpload = lazy(() => import("./app/dashboard/pages/Users/BulkUpload"));
const Roles = lazy(() => import("./app/dashboard/pages/Roles/Roles"));
const RolesTable = lazy(() => import("./app/dashboard/pages/Roles/RoleTable"));

const Audit = lazy(() => import("./app/dashboard/pages/Audit/Audit"));
const Audithome = lazy(() => import("./app/dashboard/pages/Audit/AuditTable"));

//Products Related Routes
const Product = lazy(() => import("./app/dashboard/pages/Products/Products"));
const ProductTable = lazy(() =>
  import("./app/dashboard/pages/Products/Product/ProductsTable")
);
// const CreateProduct = lazy(() =>
//   import("./app/dashboard/pages/Products/Product/CreateProduct")
// );
// const ProductItem = lazy(() =>
//   import("./app/dashboard/pages/Products/Product/ProductItem")
// );

//Product > Service Routes
const Service = lazy(() =>
  import("./app/dashboard/pages/Products/Services/ServiceHome")
);
const ProductService = lazy(() =>
  import("./app/dashboard/pages/Products/Services/CreateServices")
);
const ServiceItem = lazy(() =>
  import("./app/dashboard/pages/Products/Services/ServiceItems")
);
const ServiceTable = lazy(() =>
  import("./app/dashboard/pages/Products/Services/ServiceTable")
);

// Product > Category Routes
const ProductsCategory = lazy(() =>
  import("./app/dashboard/pages/Products/Category/ProductsCategory")
);

//Super Dealer
const SuperDealer = lazy(() =>
  import("./app/dashboard/pages/Customers/Customers")
);
const SuperDealerHome = lazy(() =>
  import("./app/dashboard/pages/Customers/Pages/SuperDealer/SuperDealerHome")
);

const SingleSuperDealer = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/SuperDealer/SubRoutes/SingleSuperDealer.js"
  )
);

const AddProductToSuperDealer = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/SuperDealer/SubRoutes/AddProductsToSuperDealer"
  )
);

const CreateSuperDealer = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/SuperDealer/SubRoutes/CreateSuperDealer.js"
  )
);

const CreateSuperDealerWithoutWallet = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/SuperDealer/SubRoutes/CreateSuperDealerWithoutWallet.js"
  )
);

//Reseller
const ResellerHome = lazy(() =>
  import("./app/dashboard/pages/Customers/Pages/Reseller/ResellerHome")
);

const SingleReseller = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Reseller/SubRoutes/SingleReseller.js"
  )
);

const CreateReseller = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Reseller/SubRoutes/CreateReseller.js"
  )
);

const UpgradeToReseller = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Reseller/SubRoutes/UpgradeToReseller.js"
  )
);

const AddProductToReseller = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Reseller/SubRoutes/AddProductsToReseller"
  )
);

// Teller
const TellerHome = lazy(() =>
  import("./app/dashboard/pages/Customers/Pages/Teller/TellerHome")
);

const SingleTeller = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Teller/SubRoutes/SingleReseller.js"
  )
);

const AgentHome = lazy(() =>
  import("./app/dashboard/pages/Customers/Pages/Agent/AgentHome")
);

const SingleAgent = lazy(() =>
  import("./app/dashboard/pages/Customers/Pages/Agent/SubRoutes/SingleAgent.js")
);

const AddProductToAgent = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Agent/SubRoutes/AddProductsToAgent"
  )
);

const CreateAgent = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Agent/SubRoutes/CreateAgents.js"
  )
);

const CreateCoporateAgentContactPerson = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Agent/SubRoutes/CreateCorporateAgent/CreateAgents_ContactPerson.js"
  )
);

const CreateCoporateAgentBusinessDetails = lazy(() =>
  import(
    "./app/dashboard/pages/Customers/Pages/Agent/SubRoutes/CreateCorporateAgent/CreateAgents_Business.js"
  )
);

//Reports
const Report = lazy(() => import("./app/dashboard/pages/Reports/Reports"));

const AccessBankReports = lazy(() =>
  import("./app/dashboard/pages/Reports/AccessBankReport/AccessBankReports")
);

const ReportProvider = lazy(() =>
  import("./app/dashboard/pages/Reports/ProviderReport")
);

const ProviderResponse = lazy(() =>
  import("./app/dashboard/pages/Reports/ProviderResponse")
);

const Transfers = lazy(() => import("./app/dashboard/pages/Reports/Transfer"));
const CustomerCashflow = lazy(() =>
  import("./app/dashboard/pages/Reports/CustomerCashflow")
);
const DataRecharge = lazy(() =>
  import("./app/dashboard/pages/Reports/DataRecharge")
);

const AirtimeRecharge = lazy(() =>
  import("./app/dashboard/pages/Reports/AirtimeRecharge")
);

const Settlement = lazy(() =>
  import("./app/dashboard/pages/Reports/Settlement")
);

const BillsPayment = lazy(() => import("./app/dashboard/pages/Reports/Bills"));

const CustomerWallet = lazy(() =>
  import("./app/dashboard/pages/Reports/CustomerWallet")
);

const LienTransactions = lazy(() =>
  import("./app/dashboard/pages/Reports/LienTransactions")
);

const TerminalWallet = lazy(() =>
  import("./app/dashboard/pages/Reports/ProviderWallet")
);

const TransactionListing = lazy(() =>
  import("./app/dashboard/pages/Reports/TransactionListing/TransactionListing")
);

const TransactionListingDay = lazy(() =>
  import(
    "./app/dashboard/pages/Reports/TransactionListing/Day/DayTransactionListing"
  )
);

const TransactionListingWeek = lazy(() =>
  import(
    "./app/dashboard/pages/Reports/TransactionListing/Week/WeekTransactionListing"
  )
);

const AgentListing = lazy(() =>
  import("./app/dashboard/pages/Reports/AgentListing/AgentListing")
);

const AgentListingDay = lazy(() =>
  import("./app/dashboard/pages/Reports/AgentListing/Day/DayAgentListing")
);

const AgentListingWeek = lazy(() =>
  import("./app/dashboard/pages/Reports/AgentListing/Week/WeekAgentListing")
);

const TerminalReportDay = lazy(() =>
  import("./app/dashboard/pages/Reports/TerminalListing/Day/DayTerminalListing")
);

const TerminalReportWeek = lazy(() =>
  import(
    "./app/dashboard/pages/Reports/TerminalListing/Week/WeekTerminalListing"
  )
);

const TradingReportDay = lazy(() =>
  import("./app/dashboard/pages/Reports/TradingListing/Day/DayTradingListing")
);

const TradingReportWeek = lazy(() =>
  import("./app/dashboard/pages/Reports/TradingListing/Week/WeekTradingListing")
);

const BankingTransaction = lazy(() =>
  import("./app/dashboard/pages/Reports/TransactionListing/TransactionListing")
);

const BalanceSheet = lazy(() =>
  import("./app/dashboard/pages/Reports/BalanceSheet")
);

//Fiance
const Finance = lazy(() => import("./app/dashboard/pages/Finance"));
const ManualFunding = lazy(() =>
  import("./app/dashboard/pages/Finance/ManualFunding")
);

const ChargeBacks = lazy(() =>
  import("./app/dashboard/pages/Finance/ChargeBacks")
);

const VFDRetrigger = lazy(() =>
  import("./app/dashboard/pages/Finance/VFD_Retrigger")
);

const Reversal = lazy(() => import("./app/dashboard/pages/Finance/Reversal"));

const BankingHome = lazy(() => import("./app/dashboard/pages/Finance/Banking"));
const ChannelsHome = lazy(() => import("./app/dashboard/pages/Finance/Channels"));
const ProcessorHome = lazy(() =>
  import("./app/dashboard/pages/Finance/ProcessorSwitch")
);
const AccountCode = lazy(() =>
  import("./app/dashboard/pages/Finance/AccountCode")
);
const TransactionLimit = lazy(() =>
  import("./app/dashboard/pages/Finance/TransactionLimit")
);

const PlanUpdates = lazy(() =>
  import("./app/dashboard/pages/Finance/planUpdate/PlanUpdates")
);

const EditDataPlan = lazy(() =>
  import("./app/dashboard/pages/Finance/planUpdate/EditDataPlan")
);

const EditDataPlanForData = lazy(() =>
  import("./app/dashboard/pages/Finance/planUpdate/EditDataPlanForData")
);

const TransactionCharge = lazy(() =>
  import("./app/dashboard/pages/Finance/TransactionCharge")
);

const TransactionChargeActions = lazy(() =>
  import("./app/dashboard/pages/Finance/TransactionChargeActions")
);

const NBTransactionCharge = lazy(() =>
  import("./app/dashboard/pages/Finance/NBTransactionCharge")
);

const NBTransactionChargeActions = lazy(() =>
  import("./app/dashboard/pages/Finance/NBTransactionChargeActions")
);

const ProviderTable = lazy(() =>
  import("./app/dashboard/pages/Finance/Provider/ProviderTable")
);

const Terminal = lazy(() =>
  import("./app/dashboard/pages/Terminal/Terminal-Mapping/Terminal")
);

const TerminalHome = lazy(() =>
  import("./app/dashboard/pages/Terminal/Terminal-Mapping/TerminalHome")
);

const TerminalItem = lazy(() =>
  import("./app/dashboard/pages/Terminal/Terminal-Mapping/TerminalItem")
);

const TerminalTypes = lazy(() =>
  import("./app/dashboard/pages/Terminal/Terminal-Types/TerminalTypes")
);

//Other Pages
const Wipay = lazy(() => import("./app/dashboard/pages/Notification/WIPAY"));
const Ecobank = lazy(() => import("./app/dashboard/pages/Notification/ECOBANK.js"));

// const ProviderPage = lazy(() =>
//   import("./app/dashboard/pages/Finance/SuperDealer/SuperDealerHome")
// );

const AddProvider = lazy(() =>
  import("./app/dashboard/pages/Finance/Provider/CreateProvider")
);

const AddProviderProducts = lazy(() =>
  import("./app/dashboard/pages/Finance/Provider/AddProductsToProvider")
);

const ProviderDeatils = lazy(() =>
  import("./app/dashboard/pages/Finance/Provider/SingleProvider")
);

const NotificationHome = lazy(() =>
  import("./app/dashboard/pages/Notification/NotificationHome")
);

const VFDHome = lazy(() => import("./app/dashboard/pages/Notification/VFD"));

const BiHome = lazy(() => import("./app/dashboard/pages/Notification/BI"));

const LUXHome = lazy(() => import("./app/dashboard/pages/Notification/LUX"));

const FincraHome = lazy(() =>
  import("./app/dashboard/pages/Notification/FINCRA")
);

const GruppHome = lazy(() =>
  import("./app/dashboard/pages/Notification/GRUPP")
);

const UPAYHome = lazy(() => import("./app/dashboard/pages/Notification/UPAY"));

const PaystackHome = lazy(() =>
  import("./app/dashboard/pages/Notification/PAYSTACK")
);

function Main() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Routes>
          {/* <Route path={HOME}>
            {apiToken ? (
              <Navigate to={{ pathname: DASHBOARD }} />
            ) : (
              <Login />
            )}
          </Route> */}

          <Route path={SETPASSWORD} element={<SetPassword />} />
          <Route element={<LoggedInRoute />}>
            <Route path={HOME} element={<Login />} />

            {/* <Route path={LOGIN} element={<Login />} /> */}
            <Route path={CREATE} element={<CreateAccount />} />
            <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path={DASHBOARD} element={<Dashboard />}>
              <Route index element={<DashboardHome />} />
              <Route path={USERS} element={<UsersHome />}>
                <Route index element={<Users />} />
                <Route path={":id/user"} element={<UserItem />} />
                <Route path={VFD_RETRIGGER} element={<VFDRetrigger />} />
                <Route path={BULK_UPLOADS} element={<BulkUpload />} />

                {/*Agent*/}
                <Route path={CUSTOMERS_AGENTS} element={<SuperDealer />}>
                  <Route index element={<AgentHome />} />
                  <Route
                    path={CUSTOMERS_CREATE_AGENT}
                    element={<CreateAgent />}
                  />
                  <Route
                    path={`:id/${CUSTOMERS_CREATE_CORPRATE_AGENT_UPLOAD}`}
                    element={<CreateCoporateAgentContactPerson />}
                  />
                  <Route
                    path={CUSTOMERS_CREATE_CORPRATE_AGENT}
                    element={<CreateCoporateAgentBusinessDetails />}
                  />
                  <Route
                    path={`:id/customer_agent`}
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route index element={<SingleAgent />} />
                    <Route
                      path={`add_agent_products`}
                      element={<AddProductToAgent />}
                    />
                  </Route>
                </Route>

                {/* Reseller */}
                <Route path={CUSTOMERS_RESELLERS} element={<SuperDealer />}>
                  <Route index element={<ResellerHome />} />

                  <Route
                    path={CUSTOMERS_CREATE_RESELLERS}
                    element={<CreateReseller />}
                  />

                  <Route path={":id/upgrade"} element={<UpgradeToReseller />} />

                  <Route
                    path={`:id/customer_reseller`}
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route index element={<SingleReseller />} />
                    <Route
                      path={`add_reseller_products`}
                      element={<AddProductToReseller />}
                    />
                  </Route>
                </Route>

                {/* Teller */}
                <Route path={CUSTOMERS_TELLERS} element={<SuperDealer />}>
                  <Route index element={<TellerHome />} />

                  <Route
                    path={CUSTOMERS_CREATE_RESELLERS}
                    element={<CreateReseller />}
                  />

                  <Route path={":id/upgrade"} element={<UpgradeToReseller />} />

                  <Route
                    path={`:id/teller`}
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route index element={<SingleTeller />} />
                    <Route
                      path={`add_reseller_products`}
                      element={<AddProductToReseller />}
                    />
                  </Route>
                </Route>

                {/* Super Dealer */}
                <Route path={CUSTOMERS_SUPER_DEALERS} element={<SuperDealer />}>
                  <Route index element={<SuperDealerHome />} />
                  <Route
                    path={CUSTOMERS_CREATE_SUPER_DEALERS}
                    element={<CreateSuperDealer />}
                  />
                  <Route
                    path={CUSTOMERS_CREATE_SUPER_DEALERS + "/without-wallet"}
                    element={<CreateSuperDealerWithoutWallet />}
                  />
                  <Route
                    path={`:id/customer_super_dealers`}
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route index element={<SingleSuperDealer />} />
                    <Route
                      path={`add_products`}
                      element={<AddProductToSuperDealer />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path={ROLES} element={<Roles />}>
                <Route index element={<RolesTable />} />
              </Route>

              {/* Audit Routes */}
              <Route path={AUDIT} element={<Audit />}>
                <Route index element={<Audithome />} />
              </Route>

              {/* Product Routes */}
              <Route path={PRODUCTS} element={<Product />}>
                <Route index element={<ProductTable />} />
                <Route path={CATEGORY} element={<ProductsCategory />} />
              </Route>
              <Route path={SERVICES} element={<Service />}>
                <Route index element={<ServiceTable />} />
                <Route path={`:id/service`} element={<ServiceItem />} />
                <Route path={CREATESERVICES} element={<ProductService />} />
              </Route>

              <Route path={REPORTS} element={<Report />}>
                <Route
                  index
                  element={
                    // <DashboardTemplate permission={AGENT_LISTING}>
                    //   {/* <p>Page not Found or Still in Development</p> */}
                    // </DashboardTemplate>
                    <AgentListing />
                  }
                />
                <Route path={"access_bank"} element={<AccessBankReports />} />
                <Route path={"agents/day"} element={<AgentListingDay />} />
                <Route
                  path={"agents/this-week"}
                  element={<AgentListingWeek />}
                />
                <Route path={"bi"} element={<BiHome />} />
                <Route
                  path={BANKING_TRAMSACTION}
                  element={<BankingTransaction />}
                />
                <Route path={BALANCE_SHEET} element={<BalanceSheet />} />
                <Route path={TRADING_BREAKDOWN} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <DashboardTemplate permission={TRADING_LISTING}>
                        {/* <p>Page not Found or Still in Development</p> */}
                      </DashboardTemplate>
                      // <TradingReport />
                    }
                  />
                  <Route path={"day"} element={<TradingReportDay />} />
                  <Route path={"this-week"} element={<TradingReportWeek />} />
                </Route>

                <Route
                  path={TERMINAL_WALLET + "/:type/:filter"}
                  element={<Outlet />}
                >
                  <Route index element={<TerminalReportDay />} />
                  <Route path={"day"} element={<TerminalReportDay />} />
                  <Route path={"this-week"} element={<TerminalReportWeek />} />
                </Route>
                <Route path={TRANSACTION_LISTING} element={<Outlet />}>
                  <Route index element={<TransactionListing />} />
                  <Route path={"day"} element={<TransactionListingDay />} />
                  <Route
                    path={"this-week"}
                    element={<TransactionListingWeek />}
                  />
                </Route>
                <Route path={CUSTOMER_WALLET} element={<CustomerWallet />} />
                <Route path={PROVIDER_WALLET} element={<TerminalWallet />} />
                <Route path={SETTLEMENT} element={<Settlement />} />
                <Route path={AIRTIME} element={<AirtimeRecharge />} />
                <Route path={DATA_RECHARGE} element={<DataRecharge />} />
                <Route path={BILLS} element={<BillsPayment />} />
                <Route path={TRANSFERS} element={<Transfers />} />
                <Route
                  path={CUSTOMER_CASHFLOW}
                  element={<CustomerCashflow />}
                />
                <Route path={PROVIDER_PAGE} element={<ReportProvider />} />
                <Route
                  path={PROVIDER_RESPONSE}
                  element={<ProviderResponse />}
                />
              </Route>

              <Route path={TERMINAL_TYPES} element={<TerminalTypes />} />
              <Route
                path={OTHER_REPORTS}
                element={
                  <>
                    <Outlet />
                  </>
                }
              >
                <Route index element={<Wipay />} />
                <Route path={WIPAY} element={<Wipay />} />
                <Route path={ECOBANK} element={<Ecobank />} />
              </Route>

              <Route path={NOTIFICATION} element={<Outlet />}>
                <Route index element={<NotificationHome />} />
                <Route path={WALLET_SETTLEMENT} element={<VFDHome />} />

                <Route
                  path={WALLET_SETTLEMENT + "/" + LUX}
                  element={<LUXHome />}
                />
                <Route
                  path={WALLET_SETTLEMENT + "/" + FINCRA + "/:type"}
                  element={<FincraHome />}
                />
                <Route
                  path={WALLET_SETTLEMENT + "/" + GRUPP}
                  element={<GruppHome />}
                />
                {/* <Route path={UPAY} element={<UPAYHome />} />
                <Route path={PAYSTACK} element={<PaystackHome />} /> */}
              </Route>

              <Route path={MAPPING} element={<Terminal />}>
                <Route index element={<TerminalHome />} />
                <Route path={":id/terminal"} element={<TerminalItem />} />
              </Route>

              <Route path={FINANCE} elment={<Finance />}>
                <Route index element={<ManualFunding />} />

                <Route path={REVERSAL} element={<Reversal />} />
                <Route path={CHARGE_BACKS} element={<ChargeBacks />} />

                <Route path={BANKING} element={<BankingHome />} />
                <Route path={CHANNELS} element={<ChannelsHome />} />
                <Route path={PROCESSOR_SWITCH} element={<ProcessorHome />} />
                <Route path={ACCOUNTCODE} element={<AccountCode />} />
                <Route path={TRANSACTIONLIMIT} element={<TransactionLimit />} />
                <Route
                  path={ADD_PROVIDER_PRPDUCTS}
                  element={<AddProviderProducts />}
                />
                <Route path={CHARGES} element={<Outlet />}>
                  <Route index element={<TransactionCharge />} />
                  <Route
                    path={CHARGES_ACTION}
                    element={<TransactionChargeActions />}
                  />
                </Route>
                {/* PlanUpdates */}
                <Route path={"plan-update"} element={<Outlet />}>
                  <Route index element={<PlanUpdates />} />
                  <Route path={":id/"} element={<EditDataPlan />} />
                  <Route path={"data/:id/"} element={<EditDataPlanForData />} />
                  {/* <Route
                    path={NB_ACTION}
                    element={<NBTransactionActions />}
                  /> */}
                </Route>

                <Route path={NB_CHARGES} element={<Outlet />}>
                  <Route index element={<NBTransactionCharge />} />
                  <Route
                    path={NB_CHARGES_ACTION}
                    element={<NBTransactionChargeActions />}
                  />
                </Route>
                <Route path={PROVIDER} element={<ProviderTable />} />
                <Route path={ADD_PROVIDER} element={<AddProvider />} />
                <Route path={PROVIDER_DETAILS} element={<ProviderDeatils />} />
                <Route
                  path={LIEN_TRANSACTIONS}
                  element={<LienTransactions />}
                />
              </Route>
              {/* Dashboard Error Routes */}
              <Route
                path={DASHERROR}
                element={<p>Page not Found or Still in Development</p>}
              />
            </Route>
          </Route>
          <Route path={ERROR} element={<h2>Page Not Found or Removed.</h2>} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default Main;
