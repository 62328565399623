import Main from "./Main";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./global/styles/style.css";
import { AuthProvider } from "./utils/context/Auth/AuthContext";
import { UserProvider } from "./utils/context/Users.js/UserContext";
import { ProductProvider } from "./utils/context/Products/ProductContext";
import { ReportProvider } from "./utils/context/Reports/ReportsContext";
import store from "./utils/redux/store";
import { TerminalProvider } from "./utils/context/Terminals/TerminalContext";
import { SidebarProvider } from "./utils/context/Sidebar/SideContext";

function App() {
  return (
    <Provider store={store}>
      <TerminalProvider>
        <ReportProvider>
          <ProductProvider>
            <UserProvider>
              <AuthProvider>
                <SidebarProvider>
                  <div className="App">
                    <Main />
                    <ToastContainer />
                  </div>
                </SidebarProvider>
              </AuthProvider>
            </UserProvider>
          </ProductProvider>
        </ReportProvider>
      </TerminalProvider>
    </Provider>
  );
}

export default App;
