import { ErrorNotification } from "../helpers/alerts";
import { HOME } from "../routes/Constant";
import redirectUser from "../routes/Redirect";

export const apiToken = JSON.parse(localStorage?.getItem("token"));

export const exitReponse = (err) => {
  if (err?.response?.status === 401) {
    ErrorNotification("Service Time Out");
    localStorage?.removeItem("token");
    redirectUser(HOME, 1000);
  } else ErrorNotification(err?.response?.data?.message);
};

// function TokenStuff(params) {
//   const { token } = useContext(AuthContext);

//   return token;
// }

// console.log("Stuff", TokenStuff());
