import api from "../api";
import { apiToken } from "../api-helpers";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const supportURL = "identity/Admin/users";

const userActions = () => {
  return {
    //Users Related Services
    addUser: function (data) {
      return api.post(`${supportURL}/createuser`, data, {
        headers: headers,
      });
    },

    getUser: function (data) {
      const { page, search, roleId, filterDate, userState, pageSize } = data;
      return api.get(
        `${supportURL}?PageSize=${pageSize === undefined ? 15 : pageSize}&CurrentPage=${
          page === undefined ? 1 : page
        }&SearchText=${search === undefined ? "" : search}&RoleId=${
          roleId === undefined ? "" : roleId
        }&FilterDate=${filterDate === undefined ? "" : ""}&UserState=${
          userState === undefined ? -1 : userState
        }`,
        {
          headers: headers,
        }
      );
    },

    getUserForRole: function (data) {
      const { page, search, roleId, filterDate, userState, pageSize } = data;
      return api.get(
        `identity/Admin/${roleId}/users?PageSize=${pageSize === undefined ? 15 : pageSize}&CurrentPage=${
          page === undefined ? 1 : page
        }&SearchText=${search === undefined ? "" : search}&RoleId=${
          roleId === undefined ? "" : roleId
        }&FilterDate=${filterDate === undefined ? "" : ""}&UserState=${
          userState === undefined ? -1 : userState
        }`,
        {
          headers: headers,
        }
      );
    },

    deactivateUser: function (data) {
      return api.post(`${supportURL}/deactivateuser`, data, {
        headers: headers,
      });
    },

    activateUser: function (data) {
      return api.post(`${supportURL}/activateuser`, data, {
        headers: headers,
      });
    },

    updateUser: function (data) {
      return api.post(`${supportURL}/updateuser`, data, {
        headers: headers,
      });
    },

    //Roles Related Service
    userRoles: function (data) {
      const { page, pageSize, roleId } = data;
      return api.get(
        `identity/Admin/roles?PageSize=${
          pageSize === undefined ? 1000 : pageSize
        }&CurrentPage=${page === undefined ? 1 : page}&RoleId=${
          page === undefined ? "" : roleId
        }`,
        {
          headers: headers,
        }
      );
    },
    createRoles: function (data) {
      return api.post("identity/Admin/roles/createrole", data, {
        headers: headers,
      });
    },
    getARole: function (roleId) {
      return api.get(`identity/Admin/${roleId}/role`, {
        headers: headers,
      });
    },
    addUserToRole: function (data) {
      return api.post("identity/Admin/user/addusertorole", data, {
        headers: headers,
      });
    },
    updateRoles: function (data) {
      return api.post("identity/Admin/roles/updaterole", data, {
        headers: headers,
      });
    },

    //Product Related Modules

    //Permission Related Services
    getPermissions: function () {
      return api.get("identity/Admin/permissions/all", {
        headers: headers,
      });
    },

    //Audit Related Services
    auditTrail: function (data) {
      const { pageSize, currentPage, filterDate, searchText, userId } = data;
      return api.get(
        `identity/Admin/audittrails?PageSize=${pageSize}&CurrentPage=${currentPage}&FilterDate=${filterDate}&SearchText=${searchText}&UserId=${userId}`,
        {
          headers: headers,
        }
      );
    },
  };
};

export const UserServices = {
  userActions: userActions,
};
