import api from "../api";
import { apiToken } from "../api-helpers";
import invapi from "../invapi";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const financeActions = () => {
  return {
    createManualFunding: function (data) {
      return api.post(`wallet/Transaction/manual_funding`, data, {
        headers: headers,
      });
    },

    regenerateVFD: function (data) {
      return api.post(`identity/Admin/users/account/regenerate`, data, {
        headers: headers,
      });
    },

    verifyProvider: function (data) {
      const { wallet_id } = data;
      return api.post(
        `identity/Transaction/validate_provider/${wallet_id}?wallet_id=${wallet_id}`,
        data,
        {
          headers: headers,
        }
      );
    },

    verifyAgent: function (data) {
      const { wallet_id } = data;
      return api.post(
        `identity/Transaction/validate_agent/${wallet_id}?wallet_id=${wallet_id}`,
        data,
        {
          headers: headers,
        }
      );
    },

    searchAgent: function (data) {
      return api.get(
        `identity/Admin/users/agentByEmail?EmailAddress=${data}`,
        data,
        {
          headers: headers,
        }
      );
    },
    createReversal: function (data) {
      return api.post(`wallet/Transaction/reversal`, data, {
        headers: headers,
      });
    },

    getBanks: function (data) {
      const { pageSize, currentPage, searchText } = data;
      return api.get(
        `identity/Admin/banks?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${searchText}`,
        {
          headers: headers,
        }
      );
    },
    getChannels: function (data) {
      return api.get(`identity/Admin/wipay/channels`, {
        headers: headers,
      });
    },
    createChannels: function (data) {
      return api.post(`identity/Admin/wipay/channels`, data, {
        headers: headers,
      });
    },
    GetProcessSwitch: function (data) {
      return api.get(`identity/Transaction/config`, {
        headers: headers,
      });
    },
    createBanks: function (data) {
      return api.post(`identity/Admin/banks`, data, {
        headers: headers,
      });
    },

    createProccessorSwitch: function (data) {
      return api.post(`identity/Transaction/config`, data, {
        headers: headers,
      });
    },
    getAccountCodes: function (data) {
      const { pageSize, currentPage, searchText, startDate } = data;
      return api.get(
        `identity/Admin/account_codes?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${searchText}&StartDate=${startDate}`,
        {
          headers: headers,
        }
      );
    },
    createAccountCodes: function (data) {
      return api.post(`identity/Admin/account_codes`, data, {
        headers: headers,
      });
    },
    getTransactionLimits: function (userId) {
      return api.get(`wallet/Transaction/transaction_limit`, {
        headers: headers,
      });
    },
    createTransactionLimit: function (data) {
      return api.post(`wallet/Transaction/transaction_limit`, data, {
        headers: headers,
      });
    },
    getTransactionCharges: function (userId) {
      return api.get(`identity/Transaction/Charges`, {
        headers: headers,
      });
    },
    getTransactionChargeType: function (data) {
      const { pageSize, currentPage, searchText } = data;
      return api.get(
        `identity/Admin/chargetype/all?pageSize=${pageSize}&currentPage=${currentPage}`,
        {
          headers: headers,
        }
      );
    },
    deleteTransactionChargeType: function (data) {
      return api.post(`identity/Admin/chargetype/delete`, data);
    },
    getTransactionChargesInv: function (userId) {
      return invapi.get(`/charges`, {
        headers: headers,
      });
    },
    updateTransactionLimit: function (data) {
      return api.post(`wallet/Transaction/transaction_limit/update`, data, {
        headers: headers,
      });
    },

    disableTransactionLimit: function (data) {
      return api.post(`wallet/Transaction/transaction_limit/deactivate`, data, {
        headers: headers,
      });
    },

    enableTransactionLimit: function (data) {
      return api.post(`wallet/Transaction/transaction_limit/activate`, data, {
        headers: headers,
      });
    },

    createTransactionCharge: function (data) {
      return api.post(`identity/Transaction/Charges`, data, {
        headers: headers,
      });
    },

    createTransactionChargeInv: function (data) {
      return invapi.put(`/charges`, data, {
        headers: headers,
      });
    },

    disableTransactionCharge: function (data) {
      return api.post(`identity/Transaction/charges/deactivate`, data, {
        headers: headers,
      });
    },

    enableTransactionCharge: function (data) {
      return api.post(`identity/Transaction/charges/activate`, data, {
        headers: headers,
      });
    },

    updateTransactionCharge: function (data) {
      return api.post(`identity/Transaction/charges/update`, data, {
        headers: headers,
      });
    },

    getProvider: function (userId) {
      return api.get(`identity/Admin/${userId}/user`, {
        headers: headers,
      });
    },
    createProvider: function (data) {
      return api.post(`identity/Admin/user`, data, {
        headers: headers,
      });
    },
  };
};

export const FinanceServices = {
  financeActions: financeActions,
};
