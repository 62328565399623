export const AuthTypes = {
     LOADING: "LOADING",
     ERROR: "ERROR",


     USER: "USER",
     EMAILCONFIRMATION: "EMAILCONFIRMATION",
     



     //Users
     USERS: "USERS",
     ADDUSER: "ADDUSER",
     UPDATEUSER: "UPDATEUSER",
     DEACTIVATEUSER: "DEACTIVATEUSER",
     ACTIVATEUSER: "ACTIVATEUSER",
     USERSONEAROLE: "USERSONEAROLE",

     //Permissions
     PERMISSIONS: "PERMISSIONS",
     



     //Roles
     AROLE: "AROLE",
     STOREROLES: "STOREROLES",


     //AUDIT
     AUDITTRAIL: "AUDITTRAIL"
}