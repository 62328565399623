import { AuthTypes } from "../types";

export const UserReducer = (state, action) => {
  switch (action.type) {
    case AuthTypes.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case AuthTypes.ERROR: {
      return {
        ...state,
        error: false,
      };
    }

    case AuthTypes.USERS: {
      return {
        ...state,
        loading: true,
        users: action.payload,
      };
    }

    case AuthTypes.USERSONEAROLE: {
      return {
        ...state,
        loading: true,
        usersOnARole: action.payload,
      };
    }
    case AuthTypes.ADDUSER: {
      return {
        ...state,
        addUsers: action.payload,
      };
    }
    case AuthTypes.UPDATEUSER: {
      return {
        ...state,
        updateUsers: action.payload,
      };
    }
    case AuthTypes.DEACTIVATEUSER: {
      return {
        ...state,
        deactivateUser: action.payload,
      };
    }
    case AuthTypes.PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case AuthTypes.STOREROLES: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case AuthTypes.AROLE: {
      return {
        ...state,
        arole: action.payload,
      };
    }

    case AuthTypes.AUDITTRAIL: {
      return {
        ...state,
        audit: action.payload,
      };
    }

    default:
      return state;
  }
};
