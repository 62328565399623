export const ReportTypes = {
  LOADING: "LOADING",

  GET_TERMINAL: "GET_TERMINAL",
  GET_TERMINAL_TYPES: "GET_TERMINAL_TYPES",
  GET_AGENTS: "GET_AGENTS",
  GET_AGENT: "GET_AGENT",
  GET_A_TERMINAL: "GET_A_TERMINAL",
  GET_AN_AGENTS: "GET_AN_AGENTS",
};
