import { ProductTypes } from "./types";

export const ProductReducer = (state, action) => {
  switch (action.type) {
    case ProductTypes.PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case ProductTypes.PRODUCT: {
      return {
        ...state,
        product: action.payload,
      };
    }

    case ProductTypes.CREATE_PRODUCT: {
      return {
        ...state,
        createProducts: action.payload,
      };
    }

    case ProductTypes.SERVICE: {
      return {
        ...state,
        service: action.payload,
      };
    }

    case ProductTypes.SERVICES: {
      return {
        ...state,
        services: action.payload,
      };
    }

    case ProductTypes.CREATE_SERVICE: {
      return {
        ...state,
        createService: action.payload,
      };
    }

    case ProductTypes.GET_PROVIDER: {
      return {
        ...state,
        provider: action.payload,
      };
    }

    default:
      return state;
  }
};
