import React, { createContext, useReducer } from "react";
import { SuccessNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { AuthTypes } from "../types";
import { ProductReducer } from "./ProductReducer";
import { ProductServices } from "./ProductServices";
import { ProductTypes } from "./types";

const initialState = {
  loading: false,
  error: false,
  createProducts: localStorage.getItem("users")
    ? JSON.parse(localStorage.getItem("users"))
    : null,
  createService: null,
  services: null,
  products: null,
  service: null,
  product: null,
  provider: [],
};

export const ProductContext = createContext(initialState);

export const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });


  function getProduct({ page, search }) {
    let data = {
      page: page,
      search: search,
    };
    ProductServices.productActions()
      .getProducts(data)
      .then((res) => {
        dispatch({
          type: ProductTypes.PRODUCTS,
          payload: res?.data,
        });
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function GetAProduct(data) {
    ProductServices.productActions()
      .getAProduct(data)
      .then((res) => {
        dispatch({
          type: ProductTypes.PRODUCT,
          payload: res?.data,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function createProduct(data) {
    ProductServices.productActions()
      .createProduct(data)
      .then((res) => {

        SuccessNotification(res?.data?.message);
        getProduct();
      })
      .catch((err) => exitReponse(err));
  }

  function ActivateProduct({ id }) {

    ProductServices.productActions()
      .activateProduct({ serviceProductId: id })
      .then((res) => {
        SuccessNotification(res?.data?.message);

        GetAProduct(id);
        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function DeactivateProduct({ id }) {
    // let data = {
    //   page: page,
    //   search: search,
    //   roleId: roleId,
    //   filterDate: filterDate,
    // };
    ProductServices.productActions()
      .deactivateProduct({ serviceProductId: id })
      .then((res) => {
        SuccessNotification(res?.data?.message);
        GetAProduct(id);
      })
      .catch((err) => exitReponse(err));
  }

  function updateProduct({
    productName,
    productDescription,
    productServiceId,
    enabled,
    productCode,
    id,
  }) {
    ProductServices.productActions()
      .updateProduct({
        id: id,
        productName: productName,
        productDescription: productDescription,
        productCode: productCode,
        enabled: enabled,
        productServiceId: productServiceId,
      })
      .then((res) => {
        GetAProduct(id);
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
    // console.log(serviceProviderId);
  }

  function getService({ page, search }) {
    let data = {
      page: page,
      search: search,
    };
    ProductServices.productActions()
      .getServices(data)
      .then((res) => {
        dispatch({
          type: ProductTypes.SERVICES,
          payload: res?.data,
        });

        scrollToTop();
      })
      .catch((err) => exitReponse(err));
  }

  function GetAService(data) {
    dispatch({
      type: AuthTypes.LOADING,
      payload: true,
    });
    ProductServices.productActions()
      .getAService(data)
      .then((res) => {
        dispatch({
          type: ProductTypes.SERVICE,
          payload: res?.data,
        });
        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  function CreateService(data) {
    ProductServices.productActions()
      .createService(data)
      .then((res) => {
        dispatch({
          type: ProductTypes.CREATE_SERVICE,
          payload: res?.data,
        });
        SuccessNotification(res?.data?.message);
        getService();
      })
      .catch((err) => exitReponse(err));
  }

  function ActivateService({ id }) {
    ProductServices.productActions()
      .activateService({ serviceId: id })
      .then((res) => {
        dispatch({
          type: ProductTypes.GET_PRODUCTS,
          payload: res?.data,
        });
        scrollToTop();
        GetAService(id);
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  function DeactivateService({ id }) {
    ProductServices.productActions()
      .deactivateService({ serviceId: id })
      .then((res) => {
        dispatch({
          type: ProductTypes.GET_PRODUCTS,
          payload: res?.data,
        });
        scrollToTop();
        GetAService(id);
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  function updateService({
    serviceName,
    serviceDescription,
    serviceProviderId,
    enabled,
    serviceKey,
    id,
  }) {
    ProductServices.productActions()
      .updateService({
        id: id,
        serviceName: serviceName,
        serviceDescription: serviceDescription,
        serviceKey: serviceKey,
        enabled: enabled,
        serviceProviderId: serviceProviderId,
      })
      .then((res) => {
        GetAService(id);
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  //Provider
  function Provider() {
    ProductServices.productActions()
      .getProvider()
      .then((res) => {
        dispatch({
          type: ProductTypes.GET_PROVIDER,
          payload: res?.data,
        });
      })
      .catch((err) => exitReponse(err));
  }

  return (
    <ProductContext.Provider
      value={{
        loading: state.loading,
        services: state.services,
        products: state.products,
        service: state.service,
        product: state.product,
        createService: state.createService,
        provider: state.provider,
        DeactivateProduct,
        ActivateProduct,
        createProduct,
        CreateService,
        GetAProduct,
        GetAService,
        updateProduct,
        updateService,
        getProduct,
        getService,
        ActivateService,
        DeactivateService,
        Provider,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
