import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SuccessNotification } from "../../helpers/alerts";
import {
  CUSTOMERS_SUPER_DEALERS,
  DASHBOARD,
  USERS,
} from "../../routes/Constant";
import { exitReponse } from "../api-helpers";
import { CustomerServices } from "./CustomerServices";
import fileDownload from "js-file-download";

const scrollToTop = (t, l) =>
  window.scrollTo({
    top: t || 0,
    left: l || 0,
    behavior: "smooth",
  });

export const Retrigger = createAsyncThunk(
  "ls/Retrigger",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().resendActivation(payload);
      scrollToTop();
      SuccessNotification("Activation Link Sent Successfully");
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

// resendActivationAccess

export const RetriggerAccess = createAsyncThunk(
  "ls/Retrigger",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().resendActivationAccess(payload);
      scrollToTop();
      SuccessNotification("Activation Link Sent Successfully");
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getOneAgent = createAsyncThunk(
  "ls/getOneAgent",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const value = payload;

    try {
      const response = await CustomerServices.customerActions().getAnAgent(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const deleteAgentProduct = createAsyncThunk(
  "ls/deleteAgentProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().deleteProduct(
        payload
      );
      SuccessNotification(response?.data?.message);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // getOneAgent(payload.agentId)
      return error?.response.data;
    }
  }
);

export const enableAgentProduct = createAsyncThunk(
  "ls/enableAgentProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().enableProduct(
        payload
      );
      SuccessNotification(response?.data?.message);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // getOneAgent(payload.agentId)
      return error?.response.data;
    }
  }
);

export const getUserByEmailPhone = createAsyncThunk(
  "ls/getUserByEmailPhone",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().searchAgentByEmailorPhoneNumber(
          payload
        );
      SuccessNotification(response?.data?.message);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const uploadResellerUser = createAsyncThunk(
  "ls/uploadResellerUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().uploadReseller(
        payload
      );
      SuccessNotification(response?.data?.message);
      //   const url = new URL(window.location.href)
      //   const obj = {
      //     size: 'M',
      //     color: 'blue',
      //     price: 29,
      //     sort: 'desc'
      // };
      //   const params = new URLSearchParams(obj);
      //   // let url = window.location.href;
      //   // let params = window.location.search;

      //   console.log(response);
      //   console.log("url", url);
      //   console.log("params", params);

      //   console.log(response?.data?.statusCode);

      //   if (response.data.statusCode === "55") {
      //     // params + "&status=55";
      //     params.append("&error=error");
      //     console.log("params",params);
      //   }
      // response.data.statusCode !== "55" &&
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 1000);
      scrollToTop();
      return response;
    } catch (error) {
      // dispatch(
      //   getAllSuperDealers({
      //     pageSize: 10000,
      //     currentPage: 1,
      //   })
      // );

      // dispatch(
      //   getAllResellers({
      //     pageSize: 10000,
      //     currentPage: 1,
      //     searchText: "",
      //   })
      // );
      exitReponse(error);
      return error?.response;
    }
  }
);

export const uploadAgentUser = createAsyncThunk(
  "ls/uploadAgentUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().uploadAgent(
        payload
      );
      SuccessNotification(response?.data?.message);
      scrollToTop();
      return response;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const createUser = createAsyncThunk(
  "ls/createUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().addUser(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getAllSystemUsers = createAsyncThunk(
  "ls/getAllSystemUsers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getUser(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);
export const getASystemUser = createAsyncThunk(
  "ls/getASystemUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getAUser(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const activateACustomer = createAsyncThunk(
  "ls/activateACustomer",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().activateCustomer(payload);
      scrollToTop();
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.message;
    }
  }
);

export const deactivateACustomer = createAsyncThunk(
  "ls/deactivateACustomer",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().deactivateCustomer(payload);
      scrollToTop();
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.message;
    }
  }
);

export const activateAUser = createAsyncThunk(
  "ls/activateAUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().activateUser(
        payload
      );
      scrollToTop();
      SuccessNotification(response?.data?.message);
      dispatch(getASystemUser(payload?.userId));
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.message;
    }
  }
);

export const deactivateAUser = createAsyncThunk(
  "ls/deactivateAUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().deactivateUser(
        payload
      );
      scrollToTop();
      dispatch(getASystemUser(payload?.userId));
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.message;
    }
  }
);

export const updateAUser = createAsyncThunk(
  "ls/updateAUser",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    // console.log(payload);
    try {
      const response = await CustomerServices.customerActions().updateUser(
        payload
      );
      // dispatch(getASystemUser(payload?.userId));
      scrollToTop();
      SuccessNotification(response?.data?.message);

      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

//Customers Module
export const getAllAgents = createAsyncThunk(
  "ls/getAllAgents",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getAgents(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getAllSuperDealerResellerAgents = createAsyncThunk(
  "ls/getAllSuperDealerResellerAgents",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getSuperDealerResellerAgents(
          payload
        );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getAllResellers = createAsyncThunk(
  "ls/getAllResellers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const value = payload;

    try {
      const response = await CustomerServices.customerActions().getResellers(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getAllTellers = createAsyncThunk(
  "ls/getAllTellers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const value = payload;

    try {
      const response = await CustomerServices.customerActions().getTellers(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getOneReseller = createAsyncThunk(
  "ls/getOneReseller",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getAReseller(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const createReseller = createAsyncThunk(
  "ls/createReseller",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().addReseller(
        payload
      );
      SuccessNotification(response?.data?.message);
      // Navigate("/superdealer")
      return response?.data;
    } catch (error) {
      dispatch(
        getAllSuperDealers({
          pageSize: 10000,
          currentPage: 1,
        })
      );
      exitReponse(error);
      return error?.response;
    }
  }
);

export const upgradeAgentToReseller = createAsyncThunk(
  "ls/upgradeAgentToReseller",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().upgradeAgent(
        payload
      );
      SuccessNotification(response?.data?.message);

      // Navigate("/superdealer")
      return response?.data;
    } catch (error) {
      dispatch(getOneAgent(payload.agentId));
      dispatch(
        getAllSuperDealers({
          pageSize: 10000,
          currentPage: 1,
        })
      );
      exitReponse(error);
      return error?.response;
    }
  }
);

export const createAgent = createAsyncThunk(
  "ls/createAgent",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().addAgent(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const createAgentCorporate = createAsyncThunk(
  "ls/createAgent",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().addAgent_corporate(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const createAgentCorporateUpload = createAsyncThunk(
  "ls/createAgent",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().addAgent_corporate_upload(
          payload
        );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const getAgentCorporateUpload = createAsyncThunk(
  "ls/createAgent",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getAgent_file(
        payload
      );
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const downloadAgentCorporateCAC = createAsyncThunk(
  "ls/getAllSuperDealers",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getAgent_file_download(
          payload
        );

      // const x = response.blob();

      // console.log(x);

      // let blob = new Blob(["hello"], "plain/text");
      // let file = blob.createObjectURL(blob);

      // console.log(file);
      console.log(response);
      fileDownload(response?.data, "file.jpg");
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const getAllSuperDealers = createAsyncThunk(
  "ls/getAllSuperDealers",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const value = payload;

    try {
      const response = await CustomerServices.customerActions().getSuperDealers(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getSuperdealerProductsList = createAsyncThunk(
  "ls/getSuperdealerProductsList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getASuperDealerProduct(
          payload
        );
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getResellerProductsList = createAsyncThunk(
  "ls/getResellerProductsList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getAResellerProduct(payload);
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getAgentProductsList = createAsyncThunk(
  "ls/getAgentProductsList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getAnAgentProduct(payload);
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getAgentWalletsList = createAsyncThunk(
  "ls/getAgentWalletsList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getAnAgentSubWallets(payload);
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getOneSuperDealer = createAsyncThunk(
  "ls/getOneSuperDealer",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getASuperDealer(
        payload
      );
      scrollToTop();
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AddMultipleResellerProduct = createAsyncThunk(
  "ls/AddMultipleResellerProduct",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().AddMultipleProductsReseller(
          payload
        );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const AddMultipleAgentProduct = createAsyncThunk(
  "ls/AddMultipleAgentProduct",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().AddMultipleProductsAgent(
          payload
        );
      SuccessNotification(response?.data?.message);

      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const createSuperdealer = createAsyncThunk(
  "ls/createSuperdealer",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().addSuperDealer(
        payload
      );

      SuccessNotification(response?.data?.message);

      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const createSuperdealerWithoutWallet = createAsyncThunk(
  "ls/createSuperdealerWithoutWallet",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().addSuperDealerWithoutWallet(
        payload
      );

      SuccessNotification(response?.data?.message);

      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const updateSuperdealer = createAsyncThunk(
  "ls/updateSuperdealer",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().editSuperDealer(
        payload
      );
      SuccessNotification(response?.data?.message);
      setTimeout(() => {
        window.location.replace(
          DASHBOARD +
            "/" +
            USERS +
            "/" +
            CUSTOMERS_SUPER_DEALERS +
            `/${payload.agentId}/customer_super_dealers`
        );
        dispatch(getOneAgent(payload.agentId));
      }, 1000);
      // Navigate("/superdealer")
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const updateReseller = createAsyncThunk(
  "ls/updateReseller",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().editReseller(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const updateAgent = createAsyncThunk(
  "ls/updateAgent",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().editAgent(
        payload
      );
      // dispatch(getOneAgent(payload.id));
      // window.location.search = "";
      SuccessNotification(response?.data?.message);
      // Navigate("/superdealer")
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const updateTeller = createAsyncThunk(
  "ls/updateTeller",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response = await CustomerServices.customerActions().editTeller(
        payload
      );
      // dispatch(getOneAgent(payload.id));
      // window.location.search = "";
      SuccessNotification(response?.data?.message);
      // Navigate("/superdealer")
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const editAgentProduct = createAsyncThunk(
  "ls/editAgentProduct",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().updateAgentProduct(payload);

      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const editResellerProduct = createAsyncThunk(
  "ls/editResellerProduct",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().updateResellerProduct(payload);
      // dispatch(getOneAgent(payload.id));
      // window.location.search = "";
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      // dispatch(getOneAgent(payload.id));
      return error?.response;
    }
  }
);

export const editSuperdealerProduct = createAsyncThunk(
  "ls/editSuperdealerProduct ",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().updateSuperDealerProduct(
          payload
        );
      // dispatch(getOneAgent(payload.agentId));
      SuccessNotification(response?.data?.message);
      // window.location.search = "";
      // getOneAgent(payload.id);

      return response?.data;
    } catch (error) {
      // dispatch(getOneAgent(payload.agentId));
      // dispatch(getProducts());
      // dispatch(getProviders());
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const AddMultipleSuperdealerProduct = createAsyncThunk(
  "ls/AddMultipleSuperdealerProduct",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    // const navigate = useNavigate()
    try {
      const response =
        await CustomerServices.customerActions().AddMultipleProductsSuperdealer(
          payload
        );
      SuccessNotification(response?.data?.message);
      // setTimeout(() => {
      //   window.location.replace(
      //     DASHBOARD +
      //       "/" +
      //       USERS +
      //       "/" +
      //       CUSTOMERS_SUPER_DEALERS +
      //       `/${payload.agentId}/customer_super_dealers`
      //   );
      // });
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const getWalletBalance = createAsyncThunk(
  "ls/getWalletBalance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getWallet(
        payload
      );
      // scrollToTop();
      // {response?.data?.isSuccessful === false
      //   ? console.log(response?.data?.message)
      //   : ""};
      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response;
    }
  }
);

export const getTransactionHistory = createAsyncThunk(
  "ls/getTransactionHistory",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getTransactions(
        payload
      );
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getTransHistory = createAsyncThunk(
  "ls/getTransHistory",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getTransactionsHistory(
          payload
        );
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getProvBal = createAsyncThunk(
  "ls/getProvBal",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getProviderBalance(payload);
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getProviders = createAsyncThunk(
  "ls/getProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getProviders(
        payload
      );
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getProducts = createAsyncThunk(
  "ls/getProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await CustomerServices.customerActions().getAllProducts(
        payload
      );
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const GetProductsByUpline = createAsyncThunk(
  "ls/GetProductsByUpline",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await CustomerServices.customerActions().getAllProductsByUpline(
          payload
        );
      // scrollToTop(40, 40);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const initialState = {};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: {
    [Retrigger.pending]: (state) => {
      return { ...state, isRetriggering: true };
    },
    [Retrigger.fulfilled]: (state, action) => {
      return {
        ...state,
        retrigger: action.payload,
        isRetriggering: false,
      };
    },
    [Retrigger.rejected]: (state, action) => {
      return {
        ...state,
        isRetriggering: false,
        error: action.payload,
      };
    },
    [deleteAgentProduct.pending]: (state) => {
      return { ...state, deletingAgentProduct: true };
    },
    [deleteAgentProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        deleteAgentProduct: action.payload,
        deletingAgentProduct: false,
      };
    },
    [deleteAgentProduct.rejected]: (state, action) => {
      return {
        ...state,
        deletingAgentProduct: false,
        error: action.payload,
      };
    },
    [upgradeAgentToReseller.pending]: (state) => {
      return { ...state, upgradingAgent: true };
    },
    [upgradeAgentToReseller.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedAgent: action.payload,
        upgradingAgent: false,
      };
    },
    [upgradeAgentToReseller.rejected]: (state, action) => {
      return {
        ...state,
        updatedAgent: action.payload,
        error: false,
      };
    },
    [getUserByEmailPhone.pending]: (state) => {
      return { ...state, searchingUser: true };
    },
    [getUserByEmailPhone.fulfilled]: (state, action) => {
      return {
        ...state,
        userByEmailPhone: action.payload,
        searchingUser: false,
      };
    },
    [getUserByEmailPhone.rejected]: (state, action) => {
      return {
        ...state,
        searchingUser: false,
        error: action.payload,
      };
    },
    [uploadResellerUser.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [uploadResellerUser.fulfilled]: (state, action) => {
      return { ...state, uploadResellerUser: action.payload, loading: false };
    },
    [uploadResellerUser.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [uploadAgentUser.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [uploadAgentUser.fulfilled]: (state, action) => {
      return { ...state, uploadAgentUser: action.payload, loading: false };
    },
    [uploadAgentUser.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    //System Users
    [createUser.pending]: (state) => {
      return { ...state, loading: true };
    },
    [createUser.fulfilled]: (state, action) => {
      return { ...state, systemUser: action.payload, loading: false };
    },
    [createUser.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false };
    },

    [getAllSystemUsers.pending]: (state) => {
      return { ...state, loading: true };
    },
    [getAllSystemUsers.fulfilled]: (state, action) => {
      return { ...state, allSystemUsers: action.payload, loading: false };
    },
    [getAllSystemUsers.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false };
    },

    [getASystemUser.pending]: (state) => {
      return { ...state, loadingSystemUser: true };
    },
    [getASystemUser.fulfilled]: (state, action) => {
      return {
        ...state,
        aSystemUser: action.payload,
        loadingSystemUser: false,
      };
    },
    [getASystemUser.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loadingSystemUser: false,
      };
    },

    [activateACustomer.pending]: (state) => {
      return { ...state, loading: true };
    },
    [activateACustomer.fulfilled]: (state, action) => {
      return { ...state, activatedCustomer: action.payload, loading: false };
    },
    [activateACustomer.rejected]: (state, action) => {
      return {
        loading: false,
        error: action.payload,
      };
    },

    [deactivateACustomer.pending]: (state) => {
      return { ...state, loading: true };
    },
    [deactivateACustomer.fulfilled]: (state, action) => {
      return { ...state, deactivatedCustomer: action.payload, loading: false };
    },
    [deactivateACustomer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [activateAUser.pending]: (state) => {
      return { loading: true };
    },
    [activateAUser.fulfilled]: (state, action) => {
      return { ...state, activatedUser: action.payload, loading: false };
    },
    [activateAUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [deactivateAUser.pending]: (state) => {
      return { loading: true };
    },
    [deactivateAUser.fulfilled]: (state, action) => {
      return { ...state, deactivatedUser: action.payload, loading: false };
    },
    [deactivateAUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [updateAUser.pending]: (state) => {
      return { ...state, loading: true };
    },
    [updateAUser.fulfilled]: (state, action) => {
      return { ...state, updatedUser: action.payload, loading: false };
    },
    [updateAUser.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false };
    },

    //Agents
    [getAllAgents.pending]: (state) => {
      return { loading: true };
    },
    [getAllAgents.fulfilled]: (state, action) => {
      return { ...state, agents: action.payload, loading: false };
    },
    [getAllAgents.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAllSuperDealerResellerAgents.pending]: (state) => {
      state.loading = true;
    },
    [getAllSuperDealerResellerAgents.fulfilled]: (state, action) => {
      return {
        ...state,
        superdealerreselleragents: action.payload,
        loading: false,
      };
    },
    [getAllSuperDealerResellerAgents.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getOneAgent.pending]: (state) => {
      state.loadingSingleSuperDealer = true;
    },
    [getOneAgent.fulfilled]: (state, action) => {
      return {
        ...state,
        agent: action.payload,
        loadingSingleSuperDealer: false,
      };
    },
    [getOneAgent.rejected]: (state, action) => {
      state.loadingSingleSuperDealer = false;
      state.error = action.payload;
    },

    [createAgent.pending]: (state) => {
      return { ...state, creatingAgent: true };
    },
    [createAgent.fulfilled]: (state, action) => {
      return {
        ...state,
        createAgent: action.payload,
        creatingAgent: false,
        isSuccess: true,
      };
    },
    [createAgent.rejected]: (state, action) => {
      return {
        ...state,
        creatingAgent: false,
        error: action.payload,
      };
    },

    //Resellers
    [getAllResellers.pending]: (state) => {
      return { ...state, loading: true };
    },
    [getAllResellers.fulfilled]: (state, action) => {
      return { ...state, resellers: action.payload, loading: false };
    },
    [getAllResellers.rejected]: (state, action) => {
      return {
        loading: false,
        error: action.payload,
      };
    },

    //Telllers
    [getAllTellers.pending]: (state) => {
      return { ...state, loading: true };
    },
    [getAllTellers.fulfilled]: (state, action) => {
      return { ...state, tellers: action.payload, loading: false };
    },
    [getAllTellers.rejected]: (state, action) => {
      return {
        loading: false,
        error: action.payload,
      };
    },

    [getOneReseller.pending]: (state) => {
      state.loading = true;
    },
    [getOneReseller.fulfilled]: (state, action) => {
      return { ...state, reseller: action.payload, loading: false };
    },
    [getOneReseller.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [createReseller.pending]: (state) => {
      return { ...state, creatingReseller: true };
    },
    [createReseller.fulfilled]: (state, action) => {
      return {
        ...state,
        createResellerr: action.payload,
        creatingReseller: false,
        isSuccess: true,
      };
    },
    [createReseller.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        creatingReseller: false,
      };
    },
    //SuperDealers
    [getAllSuperDealers.pending]: (state) => {
      return { ...state, loading: true };
    },
    [getAllSuperDealers.fulfilled]: (state, action) => {
      return { ...state, superdealers: action.payload, loading: false };
    },
    [getAllSuperDealers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [getOneSuperDealer.pending]: (state) => {
      state.loading = true;
    },
    [getOneSuperDealer.fulfilled]: (state, action) => {
      return { ...state, superdealer: action.payload, loading: false };
    },
    [getOneSuperDealer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [createSuperdealer.pending]: (state) => {
      return { ...state, creatingSuperdealer: true };
    },
    [createSuperdealer.fulfilled]: (state, action) => {
      return {
        ...state,
        createdSuperdealer: action.payload,
        creatingSuperdealer: false,
        isSuccess: true,
      };
    },
    [createSuperdealer.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        creatingSuperdealer: false,
      };
    },

    [createSuperdealerWithoutWallet.pending]: (state) => {
      return { ...state, creatingSuperdealer: true };
    },
    [createSuperdealerWithoutWallet.fulfilled]: (state, action) => {
      return {
        ...state,
        createdSuperdealer: action.payload,
        creatingSuperdealer: false,
        isSuccess: true,
      };
    },
    [createSuperdealerWithoutWallet.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        creatingSuperdealer: false,
      };
    },

    [updateSuperdealer.pending]: (state) => {
      return { ...state, updatingSuperdealer: true };
    },
    [updateSuperdealer.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedSuperdealer: action.payload,
        updatingSuperdealer: false,
        isSuccess: true,
      };
    },
    [updateSuperdealer.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        updatingSuperdealer: false,
      };
    },

    [updateReseller.pending]: (state) => {
      return { ...state, updatingReseller: true };
    },
    [updateReseller.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedReseller: action.payload,
        updatingReseller: false,
        isSuccess: true,
      };
    },
    [updateReseller.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        updatingReseller: false,
        isSuccess: true,
      };
    },

    [updateAgent.pending]: (state) => {
      return { ...state, updatingAgent: true };
    },
    [updateAgent.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedAgent: action.payload,
        updatingAgent: false,
        isSuccess: true,
      };
    },
    [updateAgent.rejected]: (state, action) => {
      return {
        ...state,
        creatingAgent: false,
        error: action.payload,
      };
    },

    [updateTeller.pending]: (state) => {
      return { ...state, updatingTeller: true };
    },
    [updateTeller.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedTeller: action.payload,
        updatingTeller: false,
        isSuccess: true,
      };
    },
    [updateTeller.rejected]: (state, action) => {
      return {
        ...state,
        creatingTeller: false,
        error: action.payload,
      };
    },

    [editSuperdealerProduct.pending]: (state) => {
      return { ...state, editingSuperdealerProduct: true };
    },
    [editSuperdealerProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        editSuperdealerProduct: action.payload,
        editingSuperdealerProduct: false,
        isSuccess: true,
      };
    },
    [editSuperdealerProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        editingSuperdealerProduct: false,
      };
    },

    [editResellerProduct.pending]: (state) => {
      return { ...state, editingResellerProduct: true };
    },
    [editResellerProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        editResellerProduct: action.payload,
        editingResellerProduct: false,
        isSuccess: true,
      };
    },
    [editResellerProduct.rejected]: (state, action) => {
      return {
        editingResellerProduct: false,
        error: action.payload,
      };
    },

    [editAgentProduct.pending]: (state) => {
      return { ...state, editingAgentProduct: true };
    },
    [editAgentProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        editAgentProduct: action.payload,
        editinAgentProduct: false,
        isSuccess: true,
      };
    },
    [editAgentProduct.rejected]: (state, action) => {
      return {
        ...state,
        editingAgentProduct: false,
        error: action.payload,
      };
    },

    [getSuperdealerProductsList.pending]: (state) => {
      state.loadingSuperdealerProducts = true;
    },
    [getSuperdealerProductsList.fulfilled]: (state, action) => {
      return {
        ...state,
        superdealersProductsLists: action.payload,
        loadingSuperdealerProducts: false,
      };
    },
    [getSuperdealerProductsList.rejected]: (state, action) => {
      state.loadingSuperdealerProducts = false;
      state.error = action.payload;
    },

    [getResellerProductsList.pending]: (state) => {
      state.loadingResellerProducts = true;
    },
    [getResellerProductsList.fulfilled]: (state, action) => {
      return {
        ...state,
        resellerProductsList: action.payload,
        loadingResellerProducts: false,
      };
    },
    [getResellerProductsList.rejected]: (state, action) => {
      state.loadingResellerProducts = false;
      state.error = action.payload;
    },

    [getAgentWalletsList.pending]: (state) => {
      return {
        ...state,
        loadingAgentWallets: true,
      };
    },
    [getAgentWalletsList.fulfilled]: (state, action) => {
      return {
        ...state,
        agentWalletsList: action.payload,
        loadingAgentWallets: false,
      };
    },
    [getAgentWalletsList.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loadingAgentWallets: true,
      };
    },

    [getAgentProductsList.pending]: (state) => {
      return {
        ...state,
        loadingAgentProducts: true,
      };
    },
    [getAgentProductsList.fulfilled]: (state, action) => {
      return {
        ...state,
        agentProductsList: action.payload,
        loadingAgentProducts: false,
      };
    },
    [getAgentProductsList.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loadingAgentProducts: true,
      };
    },

    [AddMultipleAgentProduct.pending]: (state) => {
      return {
        ...state,
        addingAgentProducts: true,
      };
    },
    [AddMultipleAgentProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        addedAgentProducts: action.payload,
        addingAgentProducts: false,
      };
    },
    [AddMultipleAgentProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        addingAgentProducts: true,
      };
    },

    [AddMultipleResellerProduct.pending]: (state) => {
      return {
        ...state,
        addingResellerProducts: true,
      };
    },
    [AddMultipleResellerProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        addedResellerProducts: action.payload,
        addingResellerProducts: false,
      };
    },
    [AddMultipleResellerProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        addingResellerProducts: true,
      };
    },

    [AddMultipleSuperdealerProduct.pending]: (state) => {
      state.addingSuperDealrProducts = true;
    },
    [AddMultipleSuperdealerProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        addedSuperDealerProducts: action.payload,
        addingSuperDealrProducts: false,
      };
    },
    [AddMultipleSuperdealerProduct.rejected]: (state, action) => {
      state.addingSuperDealrProducts = false;
      state.error = action.payload;
    },

    //Balances
    [getWalletBalance.pending]: (state) => {
      state.loading = true;
      state.balanceLoading = true;
    },
    [getWalletBalance.fulfilled]: (state, action) => {
      return {
        ...state,
        balances: action.payload,
        loading: false,
        balanceLoading: false,
      };
    },
    [getWalletBalance.rejected]: (state, action) => {
      state.loading = false;
      state.balanceLoading = false;
      state.error = action.payload;
    },

    [getTransactionHistory.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionHistory.fulfilled]: (state, action) => {
      return { ...state, transactions: action.payload, loading: false };
    },
    [getTransactionHistory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getTransHistory.pending]: (state) => {
      state.loading = true;
    },
    [getTransHistory.fulfilled]: (state, action) => {
      return { ...state, wallettransactions: action.payload, loading: false };
    },
    [getTransHistory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProvBal.pending]: (state) => {
      state.loading = true;
    },
    [getProvBal.fulfilled]: (state, action) => {
      return { ...state, provider_balance: action.payload, loading: false };
    },
    [getProvBal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getProducts.fulfilled]: (state, action) => {
      return { ...state, allProducts: action.payload, loading: false };
    },
    [getProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetProductsByUpline.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetProductsByUpline.fulfilled]: (state, action) => {
      return { ...state, allProductsByUpLine: action.payload, loading: false };
    },
    [GetProductsByUpline.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [getProviders.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getProviders.fulfilled]: (state, action) => {
      return { ...state, providers: action.payload, loading: false };
    },
    [getProviders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default customersSlice.reducer;
