import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SuccessNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { FinanceServices } from "./FinanceService";


export const AddManualFunding = createAsyncThunk(
  "ls/AddManualFunding",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createManualFunding(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const RegenerateVFD = createAsyncThunk(
  "ls/AddManualFunding",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().regenerateVFD(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ValidateAccount = createAsyncThunk(
  "ls/ValidateAccount",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      if (payload.value === "2") {
        const response = await FinanceServices.financeActions().verifyAgent(
          payload
        );
        // SuccessNotification(response?.data?.message);

        return response?.data;
      } else {
        const response = await FinanceServices.financeActions().verifyProvider(
          payload
        );
        // SuccessNotification(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      // exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ValidateAgent = createAsyncThunk(
  "ls/ValidateAccount",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().searchAgent(
        payload
      );
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const Reversal = createAsyncThunk(
  "ls/Reversal",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().createReversal(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetBanks = createAsyncThunk(
  "ls/GetBanks",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().getBanks(payload);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetChannels = createAsyncThunk(
  "ls/GetBanks",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().getChannels(payload);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

// /Transaction/config
export const GetProcessSwitch = createAsyncThunk(
  "ls/GetProcessSwitch",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().GetProcessSwitch(
        payload
      );
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateProcessorSwitch = createAsyncThunk(
  "ls/CreateProcessorSwitch",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createProccessorSwitch(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateBanks = createAsyncThunk(
  "ls/CreateBanks",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().createBanks(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateChannels = createAsyncThunk(
  "ls/CreateBanks",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().createChannels(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetAccountCodes = createAsyncThunk(
  "ls/GetAccountCodes",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().getAccountCodes(
        payload
      );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateAccountCodes = createAsyncThunk(
  "ls/CreateAccountCodes",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createAccountCodes(payload);
      SuccessNotification(response?.data?.message);
      dispatch(
        GetAccountCodes({
          currentPage: 1,
          pageSize: 10,
        })
      );
      return response?.data;
    } catch (error) {
      exitReponse(error);
      dispatch(
        GetAccountCodes({
          currentPage: 1,
          pageSize: 10,
        })
      );
      return error?.response?.data;
    }
  }
);

export const GetTransactionLimits = createAsyncThunk(
  "ls/GetTransactionLimits",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().getTransactionLimits(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateTransactionLimit = createAsyncThunk(
  "ls/CreateTransactionLimit",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createTransactionLimit(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const UpdateTransactionCharges = createAsyncThunk(
  "ls/UpdateTransactionCharges",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().updateTransactionCharge(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ActivateTransactionLimit = createAsyncThunk(
  "ls/ActivateTransactionLimit",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().enableTransactionLimit(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const DeactivateTransactionLimit = createAsyncThunk(
  "ls/DeactivateTransactionLimit",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().disableTransactionLimit(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetTransactionCharges = createAsyncThunk(
  "ls/GetTransactionCharges",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().getTransactionCharges(payload);
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetTransactionChargeType = createAsyncThunk(
  "ls/GetTransactionChargeType",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().getTransactionChargeType(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const DeleteTransactionChargeType = createAsyncThunk(
  "ls/DeleteTransactionChargeType",

  async (payload, { rejectWithValue, getState, dispatch }) => {
   
    try {
      const response =
        await FinanceServices.financeActions().deleteTransactionChargeType(
          payload
        );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetTransactionChargesINV = createAsyncThunk(
  "ls/GetTransactionCharges",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().getTransactionChargesInv(
          payload
        );
      // SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateTransactionCharge = createAsyncThunk(
  "ls/CreateTransactionCharge",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createTransactionCharge(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      // rejectWithValue(error?.response?.data);
      return error?.response?.data;
    }
  }
);

export const CreateTransactionChargeINV = createAsyncThunk(
  "ls/CreateTransactionCharge",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().createTransactionChargeInv(
          payload
        );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      // rejectWithValue(error?.response?.data);
      return error?.response?.data;
    }
  }
);

export const UpdateTransactionLimit = createAsyncThunk(
  "ls/UpdateTransactionLimit",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().updateTransactionLimit(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const ActivateTransactionCharges = createAsyncThunk(
  "ls/ActivateTransactionCharges",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().enableTransactionCharge(payload);
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const DeactivateTransactionCharges = createAsyncThunk(
  "ls/DeactivateTransactionCharges",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await FinanceServices.financeActions().disableTransactionCharge(
          payload
        );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const GetProviders = createAsyncThunk(
  "ls/GetProviders",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().getProvider(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

export const CreateProvider = createAsyncThunk(
  "ls/CreateProvider",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await FinanceServices.financeActions().createProvider(
        payload
      );
      SuccessNotification(response?.data?.message);
      return response?.data;
    } catch (error) {
      exitReponse(error);
      return error?.response?.data;
    }
  }
);

const initialState = {
  banks: {},
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: {
    [ValidateAccount.pending]: (state) => {
      return {
        ...state,
        validatingAccount: true,
      };
    },
    [ValidateAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        validatedAccount: action.payload,
        validatingAccount: false,
      };
    },
    [ValidateAccount.rejected]: (state, action) => {
      return {
        ...state,
        validatingAccount: false,
        error: action.payload,
      };
    },

    [AddManualFunding.pending]: (state) => {
      return {
        ...state,
        ManualFundingIsCreating: true,
      };
    },
    [AddManualFunding.fulfilled]: (state, action) => {
      return {
        ...state,
        creatingManualFunding: action.payload,
        ManualFundingIsCreating: false,
      };
    },
    [AddManualFunding.rejected]: (state, action) => {
      return {
        ...state,
        ManualFundingIsCreating: false,
        error: action.payload,
      };
    },

    [Reversal.pending]: (state) => {
      return { ...state, ReversalIsCreating: true };
    },
    [Reversal.fulfilled]: (state, action) => {
      return {
        ...state,
        creatingReversal: action.payload,
        ReversalIsCreating: false,
      };
    },
    [Reversal.rejected]: (state, action) => {
      return {
        ...state,
        ReversalIsCreating: false,
        error: action.payload,
      };
    },

    [GetBanks.pending]: (state) => {
      return { GettingBanks: true };
    },
    [GetBanks.fulfilled]: (state, action) => {
      return {
        ...state,
        banks: action.payload,
        GettingBanks: false,
      };
    },
    [GetBanks.rejected]: (state, action) => {
      state.GettingBanks = false;
      state.error = action.payload;
    },

    //GetProcessSwitch
    [GetProcessSwitch.pending]: (state) => {
      return { GettingBanks: true };
    },
    [GetProcessSwitch.fulfilled]: (state, action) => {
      return {
        ...state,
        processSwitchDetails: action.payload,
        GettingBanks: false,
      };
    },
    [GetProcessSwitch.rejected]: (state, action) => {
      state.GettingBanks = false;
      state.error = action.payload;
    },

    [CreateProcessorSwitch.pending]: (state) => {
      return { ...state, processorLoading: true };
    },
    [CreateProcessorSwitch.fulfilled]: (state, action) => {
      return {
        ...state,
        processorSwitch: action.payload,
        processorLoading: false,
      };
    },
    [CreateProcessorSwitch.rejected]: (state, action) => {
      return {
        ...state,
        processorLoading: false,
        error: action.payload,
      };
    },

    [CreateBanks.pending]: (state) => {
      return { BankIsCreating: true };
    },
    [CreateBanks.fulfilled]: (state, action) => {
      return {
        ...state,
        createdBank: action.payload,
        BankIsCreating: false,
      };
    },
    [CreateBanks.rejected]: (state, action) => {
      state.BankIsCreating = false;
      state.error = action.payload;
    },

    [GetAccountCodes.pending]: (state) => {
      return { GettingAccountCodes: true };
    },
    [GetAccountCodes.fulfilled]: (state, action) => {
      return {
        ...state,
        accountCodes: action.payload,
        GettingAccountCodes: false,
      };
    },
    [GetAccountCodes.rejected]: (state, action) => {
      state.GettingAccountCodes = false;
      state.error = action.payload;
    },

    [CreateAccountCodes.pending]: (state) => {
      return { AccountCodeIsCreating: true };
    },
    [CreateAccountCodes.fulfilled]: (state, action) => {
      return {
        ...state,
        createdAccountCode: action.payload,
        AccountCodeIsCreating: false,
      };
    },
    [CreateAccountCodes.rejected]: (state, action) => {
      state.AccountCodeIsCreating = false;
      state.error = action.payload;
    },

    [GetTransactionLimits.pending]: (state) => {
      return { ...state, GettingTransactionLimits: true };
    },
    [GetTransactionLimits.fulfilled]: (state, action) => {
      return {
        ...state,
        transactionLimits: action.payload,
        GettingTransactionLimits: false,
      };
    },
    [GetTransactionLimits.rejected]: (state, action) => {
      return {
        ...state,
        GettingTransactionLimits: false,
        error: action.payload,
      };
    },

    [CreateTransactionLimit.pending]: (state) => {
      return { ...state, TransactionLimitsIsCreating: true };
    },
    [CreateTransactionLimit.fulfilled]: (state, action) => {
      return {
        ...state,
        createdTransactionLimits: action.payload,
        TransactionLimitsIsCreating: false,
      };
    },
    [CreateTransactionLimit.rejected]: (state, action) => {
      return {
        ...state,
        TransactionLimitsIsCreating: false,
        error: action.payload,
      };
    },

    [UpdateTransactionLimit.pending]: (state) => {
      return { ...state, TransactionLimitsIsCreating: true };
    },
    [UpdateTransactionLimit.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedTransactionLimits: action.payload,
        TransactionLimitsIsCreating: false,
      };
    },
    [UpdateTransactionLimit.rejected]: (state, action) => {
      return {
        ...state,
        TransactionLimitsIsCreating: false,
        error: action.payload,
      };
    },

    [GetTransactionCharges.pending]: (state) => {
      return { ...state, GettingTransactionCharges: true };
    },
    [GetTransactionCharges.fulfilled]: (state, action) => {
      return {
        ...state,
        transactionCharges: action.payload,
        GettingTransactionCharges: false,
      };
    },
    [GetTransactionCharges.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        GettingTransactionCharges: false,
      };
    },

    // GetTransactionChargeType
    [GetTransactionChargeType.pending]: (state) => {
      return { ...state, GettingTransactionChargeType: true };
    },
    [GetTransactionChargeType.fulfilled]: (state, action) => {
      return {
        ...state,
        transactionChargeType: action.payload,
        GettingTransactionChargeType: false,
      };
    },
    [GetTransactionChargeType.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        GettingTransactionChargeType: false,
      };
    },

    [DeleteTransactionChargeType.pending]: (state) => {
      return { ...state, deletingTransactionChargeType: true };
    },
    [DeleteTransactionChargeType.fulfilled]: (state, action) => {
      return {
        ...state,
        deteledTransactionChargeType: action.payload,
        deletingTransactionChargeType: false,
      };
    },
    [DeleteTransactionChargeType.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        deletingTransactionChargeType: false,
      };
    },

    [CreateTransactionCharge.pending]: (state) => {
      return { ...state, TransactionChargesIsCreating: true };
    },
    [CreateTransactionCharge.fulfilled]: (state, action) => {
      return {
        ...state,
        createdTransactionCharges: action.payload,
        TransactionChargesIsCreating: false,
      };
    },
    [CreateTransactionCharge.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        TransactionChargesIsCreating: false,
      };
    },

    [UpdateTransactionCharges.pending]: (state) => {
      return { ...state, TransactionChargesIsCreating: true };
    },
    [UpdateTransactionCharges.fulfilled]: (state, action) => {
      return {
        ...state,
        updatedTransactionCharges: action.payload,
        TransactionChargesIsCreating: false,
      };
    },
    [UpdateTransactionCharges.rejected]: (state, action) => {
      return {
        ...state,
        TransactionChargesIsCreating: false,
        error: action.payload,
      };
    },

    [GetProviders.pending]: (state) => {
      return { GettingProviders: true };
    },
    [GetProviders.fulfilled]: (state, action) => {
      return {
        ...state,
        providers: action.payload,
        GettingProviders: false,
      };
    },
    [GetProviders.rejected]: (state, action) => {
      state.GettingProviders = false;
      state.error = action.payload;
    },

    [CreateProvider.pending]: (state) => {
      return { ProvidersIsCreating: true };
    },
    [CreateProvider.fulfilled]: (state, action) => {
      return {
        ...state,
        createdProviders: action.payload,
        ProvidersIsCreating: false,
      };
    },
    [CreateProvider.rejected]: (state, action) => {
      state.ProvidersIsCreating = false;
      state.error = action.payload;
    },
  },
});

export default customersSlice.reducer;
