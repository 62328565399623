import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { exitReponse } from "../context/api-helpers";
import { ProductServices } from "../context/Products/ProductServices";
import { SuccessNotification, ErrorNotification } from "../helpers/alerts";

export const createProduct = createAsyncThunk(
  "ls/createProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().createProduct(
        value
      );
      SuccessNotification(response?.data?.message);
      window.location.reload();
      return response;
    } catch (error) {
      ErrorNotification(error?.response?.data?.message);
      exitReponse(error?.response?.data?.message);
      return error?.response;
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "ls/getAllProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().getProducts(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const createService = createAsyncThunk(
  "ls/createService",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().createService(
        value
      );

      if (response) {
        await ProductServices.productActions().getServices(value);
        SuccessNotification(response?.data?.message);
      }
      window.location.reload();
      return response;
    } catch (error) {
      ErrorNotification(error?.response?.data?.message);
      exitReponse(error?.response?.data?.message);
      return error?.response;
    }
  }
);

export const getAllServices = createAsyncThunk(
  "ls/getAllServices",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().getServices(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const getAllProviders = createAsyncThunk(
  "ls/getAllProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().getProviders(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const createProvider = createAsyncThunk(
  "ls/createProvider",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().createProvider(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error);
      return error?.response;
    }
  }
);

export const updateProvider = createAsyncThunk(
  "ls/updateProvider",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().updateProvider(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response;
    }
  }
);

export const enableProvider = createAsyncThunk(
  "ls/enableProvider",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().enableProvider(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response?.data;
    }
  }
);

export const disableProvider = createAsyncThunk(
  "ls/disableProvider",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().disableProvider(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response?.data;
    }
  }
);

export const editProviderProduct = createAsyncThunk(
  "ls/editProviderProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().editProviderProduct(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response?.data;
    }
  }
);

export const enableProviderProduct = createAsyncThunk(
  "ls/enableProviderProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().enableProviderProduct(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response?.data;
    }
  }
);

export const disableProviderProduct = createAsyncThunk(
  "ls/disableProviderProduct",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;

    try {
      const response = await ProductServices.productActions().disableProviderProduct(
        value
      );
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      exitReponse(error)
      return error?.response?.data;
    }
  }
);

export const createProviderProducts = createAsyncThunk(
  "ls/createProviderProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response =
        await ProductServices.productActions().createProviderProducts(value);
      // setTimeout(() => {
      //   window.location.replace(`/dashboard/finance/provider`);
      // }, 1000);
      SuccessNotification(response?.data?.message);
      return response.data;
    } catch (error) {
      ErrorNotification(error?.response?.data?.message);
      exitReponse(error?.response?.data?.message);
      return error?.response;
    }
  }
);

// getProviderByID
export const getProvider = createAsyncThunk(
  "ls/getProvider",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const value = payload;
    try {
      const response = await ProductServices.productActions().getProvider(
        value
      );
      return response.data;
    } catch (error) {
      return error?.response;
    }
  }
);

const initialState = {
  auth: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    //Fetch Transactions
    [createProduct.pending]: (state) => {
      state.loading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      return { ...state, createProduct: action.payload, loading: false };
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Fetch Transactions
    [createService.pending]: (state) => {
      state.loading = true;
    },
    [createService.fulfilled]: (state, action) => {
      return { ...state, createService: action.payload, loading: false };
    },
    [createService.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAllServices.pending]: (state) => {
      state.loading = true;
    },
    [getAllServices.fulfilled]: (state, action) => {
      return { ...state, allServices: action.payload, loading: false };
    },
    [getAllServices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAllProducts.pending]: (state) => {
      state.loading = true;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      return { ...state, allProducts: action.payload, loading: false };
    },
    [getAllProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAllProviders.pending]: (state) => {
      state.loading = true;
    },
    [getAllProviders.fulfilled]: (state, action) => {
      return { ...state, allProviders: action.payload, loading: false };
    },
    [getAllProviders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProvider.pending]: (state) => {
      state.loading = true;
    },
    [getProvider.fulfilled]: (state, action) => {
      return { ...state, getProvider: action.payload, loading: false };
    },
    [getProvider.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [createProvider.pending]: (state) => {
      return { loading: true };
    },
    [createProvider.fulfilled]: (state, action) => {
      return {
        ...state,
        createProviderProduct: action.payload,
        loading: false,
      };
    },
    [createProvider.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },

    [updateProvider.pending]: (state) => {
      return { ...state, loading: true };
    },
    [updateProvider.fulfilled]: (state, action) => {
      return {
        ...state,
        updateProvider: action.payload,
        loading: false,
      };
    },
    [updateProvider.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },

    [enableProvider.pending]: (state) => {
      return { ...state, loading: true };
    },
    [enableProvider.fulfilled]: (state, action) => {
      return {
        ...state,
        enabledProvider: action.payload,
        loading: false,
      };
    },
    [enableProvider.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },


    [disableProvider.pending]: (state) => {
      return { ...state, loading: true };
    },
    [disableProvider.fulfilled]: (state, action) => {
      return {
        ...state,
        disabledProvider: action.payload,
        loading: false,
      };
    },
    [disableProvider.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },

    [editProviderProduct.pending]: (state) => {
      return { ...state, loading: true };
    },
    [editProviderProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        editProviderProduct: action.payload,
        loading: false,
      };
    },
    [editProviderProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },

    [enableProviderProduct.pending]: (state) => {
      return { ...state, loading: true };
    },
    [enableProviderProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        enabledProviderProduct: action.payload,
        loading: false,
      };
    },
    [enableProviderProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },


    [disableProviderProduct.pending]: (state) => {
      return { ...state, loading: true };
    },
    [disableProviderProduct.fulfilled]: (state, action) => {
      return {
        ...state,
        disabledProviderProduct: action.payload,
        loading: false,
      };
    },
    [disableProviderProduct.rejected]: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },

    [createProviderProducts.pending]: (state) => {
      state.loading = true;
    },
    [createProviderProducts.fulfilled]: (state, action) => {
      return {
        ...state,
        createProviderProduct: action.payload,
        loading: false,
      };
    },
    [createProviderProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Fetch User
    // [addBeneficiary.pending]: (state) => {
    //   state.loading = true;
    // },
    // [addBeneficiary.fulfilled]: (state, action) => {
    //   return { ...state, beneficiary: action.payload, loading: false };
    // },
    // [addBeneficiary.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    // //Fetch Beneficiary
    // [fetchBeneficiary.pending]: (state) => {
    //   state.loading = true;
    // },
    // [fetchBeneficiary.fulfilled]: (state, action) => {
    //   return { ...state, beneficiary: action.payload, loading: false };
    // },
    // [fetchBeneficiary.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});
// export const AppUser = (state) => state.app.user;
// export const AppTransactions = (state) => state.app.transaction;

export default authSlice.reducer;
