//Dashboard 
export const DASHBOARD_REPORTS = "p_dashboard_reports"


//User Management Permissions

export const VIEW_SYSTEM_USERS = "p_view_system_user";

export const EDIT_SYSTEM_USERS = "p_edit_system";

export const ADD_SUPERDEALER = "p_add_super_dealer";

export const VIEW_SUPERDEALER = "p_view_super_dealer";

export const EDIT_SUPERDEALER = "p_edit_super_dealer_profile";

export const EDIT_SUPERDEALER_PRODUCT = "p_edit_super_dealer_product";

export const ACTIVATE_DEACTIVATE_SUPERDEALER_PRODUCT =
  "p_activate_deactivate_super_dealer_product";

export const ADD_MORE_SUPERDEALER_PRODUCT = "p_add_more_product_super_dealer";

export const DEACTIVE_ACTIVATE_SUPERDEALER =
  "p_deactivate_activate_super_dealer";

export const ADD_RESELLER = "p_add_reseller";

export const VIEW_RESELLER = "p_view_reseller";

export const EDIT_RESELLER = "p_edit_reseller_profile";

export const EDIT_RESELLER_PRODUCT = "p_edit_edit_reseller_product";

export const ACTIVATE_DEACTIVATE_RESELLER_PRODUCT =
  "p_activate_deactivate_reseller_product";

export const ADD_MORE_RESELLER_PRODUCT = "p_add_more_product_reseller";

export const DEACTIVE_ACTIVATE_RESELLER = "p_deactivate_activate_reseller";

export const CHANGE_RESELLER_UPLINE = "p_change_reseller_upline";

export const UPGRADE_AGENT_TO_RESELLER = "p_upgrade_agent_to_reseller";

export const ADD_AGENT = "p_add_agent";

export const VIEW_AGENT = "p_view_agent";

export const EDIT_AGENT = "p_edit_agent_profile";

export const EDIT_AGENT_PRODUCT = "p_edit_agent_product";

export const ACTIVATE_DEACTIVATE_AGENT_PRODUCT =
  "p_activate_deactivate_agent_product";

export const ADD_MORE_AGENT_PRODUCT = "p_add_more_product_agent";

export const DEACTIVE_ACTIVATE_AGENT = "p_deactivate_activate_agent";

export const CHANGE_AGENT_UPLINE = "p_change_agent_upline";

export const BULK_UPLOAD_AGENT = "p_bulk_upload_agent";

export const BULK_UPLOAD_RESELLER = "p_bulk_upload_reseller";




//rOLES


export const CREATE_ROLE = "p_create_role"

export const EDIT_ROLE = "p_edit_role"

export const ASSIGN_USER_TO_ROLE = "p_add_user_to_role"



//Terminal

export const UPLOAD_TERMINAL = "p_upload_terminal"

export const VIEW_TERMINAL = "p_view_terminals"

export const ASSIGN_TERMINAL = "p_assign_terminals"

export const UNASSIGN_TERMINAL = "p_unassign_terminals"

export const  ADD_TERMINAL_TYPE = "p_add_terminal_type"

export const  VIEW_TERMINAL_TYPE = "p_view_terminal_type"



//Service/Product

export const ADD_SERVICE = "p_add_service"

export const VIEW_SERVICE = "p_view_service"

export const EDIT_SERVICE = "p_edit_service"

export const ADD_PRODUCT = "p_add_product"

export const VIEW_PRODUCT = "p_view_product"

export const EDIT_PRODUCT = "p_edit_product"



//Finance

export const REVERSAL = "p_reversal"

export const MANUAL_FUNDING = "p_manual_funding"

export const SET_TRANSACTION_CHARGES = "p_set_transaction_charges"

export const VIEW_TRANSACTION_CHARGES = "p_view_transaction_charges"

export const EDIT_TRANSACTION_CHARGES = "p_edit_transaction_charges"

export const SET_TRANSACTION_LIMIT = "p_set_transaction_limit"

export const VIEW_TRANSACTION_LIMIT = "p_view_transaction_limit"

export const EDIT_TRANSACTION_LIMIT = "p_edit_transaction_limit"

export const VIEW_PROVIDERS = "p_view_providers"

export const ADD_PROVIDER = "p_add_provider"

export const EDIT_PROVIDER_DETAILS = "p_edit_provider_details"

export const ADD_MORE_PRODUCT_TO_PROVIDER = "p_add_more_product_to_provider"

export const EDIT_PROVIDER = "p_edit_provider"

export const DEACTIVE_ACTIVATE_PROVIDER = "p_deactivate_activate_provider_product"

export const SET_CUSTOMER_ACCOUNT_CODE = "p_set_customer_account_code"

export const VIEW_CUSTOMER_ACCOUNT_CODE = "p_view_customer_account_code"

export const SET_BANK = "p_set_up_bank"

export const VIEW_BANK = "p_view_bank"




//Reports
export const AGENT_LISTING = "p_view_Agent_listing"

export const TERMINAL_REPORT_LISTING = "p_view_terminal_report"

export const TRADING_LISTING = "p_view_trading_breakdown"

export const ACCESS_BANK_REPORT = "access_bank"




export const CUSTOMER_LISTING = "p_view_customer_wallet"

export const PROVIDER_WALLET_LISTING = "p_view_provider_wallet"

export const PROVIDER_LISTING = "p_view_provider_listing"

export const VIEW_BALANCE_SHEET = "p_view_balance_sheet"



//WIPAY
export const WIPAY_TRANSREQ = "p_view_transaction_request"

export const WIPAY_TRANSNOT = "p_view_transaction_notification"

export const WIPAY_TRANSPLIT = "p_view_transaction_split"

export const WIPAY_AGGTRANSPLIT = "p_view_aggregated_transaction_split"




//Audit
export const VIEW_AUDIT_TRAIL = "p_view_audit_trail"














//User Permissions

export const VIEW_USERS = "p_view_user"

// export const CREATE_USER = "p_create_user"

export const ACTIVATE_DEACTIVATE_USER = "p_activate_user"

export const EDIT_USER = "p_edit_user"



//Role Module








//Notification Module
export const VIEW_NOTIFICATIONS_PAYSTACK = "p_view_notifications_paystack"

export const VIEW_NOTIFICATIONS_LUX = "p_view_notifications_lux"

export const VIEW_NOTIFICATIONS_UPAY = "p_view_notifications_upay"

export const VIEW_NOTIFICATIONS_CORAL = "p_view_notifications_coral"

export const VIEW_NOTIFICATIONS_VFD = "p_view_notifications_vfd"

export const VIEW_NOTIFICATIONS_WIPAY = "p_view_notifications_wipay"



// Report Module
export const VIEW_REPORT_TRANSFER = "p_view_report_transfer_transactions"

export const VIEW_REPORT_SETTLEMENT  = "p_view_report_settlement"

export const VIEW_REPORT_DATA = "p_view_report_data"

export const VIEW_REPORT_AIRTIME = "p_view_report_airtime"

export const VIEW_REPORT_BILLS = "p_view_report_bills"

export const VIEW_PROVIDER_BALANCE = "p_view_provider_balance"


//Customer Module
export const VIEW_CUSTOMER = "p_view_customers"


























//Permited Routes

export const PERMITTED_ROUTES__ = {
  USERS: 1,

  ROLES: 2,

  TERMINAL: 5,

  AUDIT: 6,

  REPORT: 7,

  NOTIFICATIONS: 8,

  CUSTOMER: 9,

  PROVIDER: 10,
};



export const PERMITTED_ROUTES = {
     USERS: 1,
   
     ROLES: 2,
   
     TERMINAL: 3,

     PRODUCTS_SERVICES: 4,
     
     FINANCE: 5,
   
     REPORT: 6,

     CUSTOM_REPORT: 7,

     AUDIT: 8,

     DASHBOARD: 9,

     NOTIFICATION: 10
   };
