import React, { createContext, useReducer } from "react";
import { SuccessNotification } from "../../helpers/alerts";
import { exitReponse } from "../api-helpers";
import { AuthTypes } from "../types";
import { UserReducer } from "./UserReducer";
import { UserServices } from "./UserServices";

const initialState = {
  loading: null,

  users: localStorage.getItem("users")
    ? JSON.parse(localStorage.getItem("users"))
    : null,
  usersOnARole: [],
  addUsers: null,
  updateUsers: null,
  permissions: [],
  roles: [],
  arole: [],
  deactivateUsers: null,
  audit: null,
  updateFormState: false,
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  //Users Related Calls
  function getUsers({ page, searchText, roleId, filterDate, userState }) {
    let data = {
      page: page,
      search: searchText,
      roleId: roleId,
      filterDate: filterDate,
      userState: userState,
    };
    scrollToTop();
    dispatch({
      type: AuthTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: AuthTypes.USERS,
      payload: null,
    });
    UserServices.userActions()
      .getUser(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.USERS,
          payload: res?.data,
        });
        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  function getUsersOnARole({
    page,
    searchText,
    roleId,
    filterDate,
    userState,
  }) {
    let data = {
      page: page,
      search: searchText,
      roleId: roleId,
      filterDate: filterDate,
      userState: userState,
    };
    dispatch({
      type: AuthTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: AuthTypes.USERSONEAROLE,
      payload: null,
    });
    UserServices.userActions()
      .getUserForRole(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.USERSONEAROLE,
          payload: res?.data,
        });
        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  function addUser({ data, page }) {
    UserServices.userActions()
      .addUser(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.ADDUSER,
          payload: res?.data?.result,
        });
        getUsers({ page });
      })
      .catch((err) => exitReponse(err));
  }

  function deactivateUser({ userId, comment, page, userState }) {
    UserServices.userActions()
      .deactivateUser({
        userId: userId,
        comment: comment,
      })
      .then((res) => {
        SuccessNotification(res?.data?.message);
        // dispatch({
        //   type: AuthTypes.DEACTIVATEUSER,
        //   payload: res?.data,
        // });
        getUsers({ page, userState });
      })
      .catch((err) => exitReponse(err));
  }

  function activateUser({ userId, comment, page, userState }) {
    UserServices.userActions()
      .activateUser({
        userId: userId,
        comment: comment,
      })
      .then((res) => {
        SuccessNotification(res?.data?.message);
        getUsers({ page, userState });
      })
      .catch((err) => exitReponse(err));
  }

  function updateUser({ data, page, userState }) {
    UserServices.userActions()
      .updateUser(data)
      .then((res) => {
        // SuccessNotification(res?.data?.message);
        dispatch({
          type: AuthTypes.UPDATEUSER,
          payload: res?.data?.result,
        });
        getUsers({ page, userState });

        // if (res?.data?.result) {
        //   getUsers({ page, userState });
        //   setTimeout(
        //     dispatch({
        //       type: AuthTypes.UPDATEUSER,
        //       payload: null,
        //     }),
        //     10
        //   );
        // }
      })
      .catch((err) => exitReponse(err));
  }

  //Roles Related Calls
  function userRoles({ page, search, roleId, filterDate, pageSize }) {
    let data = {
      page: page,
      // search: search,
      roleId: roleId,
      pageSize: pageSize,
      // filterDate: filterDate,
    };

    UserServices.userActions()
      .userRoles(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.STOREROLES,
          payload: res?.data,
        });
        scrollToTop();
        SuccessNotification(res?.data?.message);
      })
      .catch((err) => exitReponse(err));
  }

  function getARoles(roleId) {
    dispatch({
      type: AuthTypes.LOADING,
      payload: true,
    });

    dispatch({
      type: AuthTypes.AROLE,
      payload: null,
    });
    UserServices.userActions()
      .getARole(roleId)
      .then((res) => {
        dispatch({
          type: AuthTypes.AROLE,
          payload: res?.data,
        });
        dispatch({
          type: AuthTypes.LOADING,
          payload: false,
        });
      })
      .catch((err) => exitReponse(err));
  }

  function createRoles(data) {
    UserServices.userActions()
      .createRoles(data)
      .then((res) => {
        SuccessNotification(res?.data?.message);
        userRoles({
          page: data?.page,
          pageSize: 15,
        });
        window.history.go(-1);
      })
      .catch((err) => exitReponse(err));
  }


  function addUserToRoles(data) {
    UserServices.userActions()
      .addUserToRole(data)
      .then((res) => {
        SuccessNotification(res?.data?.message);
        userRoles({
          page: data?.page,
          pageSize: 15,
        });
        // window.history.go(-1);
      })
      .catch((err) => exitReponse(err));
  }

  

  function updateRole({ data, id }) {
    UserServices.userActions()
      .updateRoles(data)
      .then((res) => {
        SuccessNotification(res?.data?.message);
        userRoles({
          page: data?.page,
          pageSize: 15,
        });
        window.history.go(-1);
      })
      .catch((err) => exitReponse(err));
  }

  //Permissions Related Calls
  function getPermissions(data) {
    UserServices.userActions()
      .getPermissions()
      .then((res) => {
        dispatch({
          type: AuthTypes.PERMISSIONS,
          payload: res?.data,
        });
      })
      .catch((err) => exitReponse(err));
  }

  //audit
  function auditTrail(data) {
    UserServices.userActions()
      .auditTrail(data)
      .then((res) => {
        dispatch({
          type: AuthTypes.AUDITTRAIL,
          payload: res?.data,
        });
      })
      .catch((err) => exitReponse(err));
  }

  return (
    <UserContext.Provider
      value={{
        loading: state.loading,
        addUser,
        getPermissions,
        createRoles,
        userRoles,
        getUsers,
        getUsersOnARole,
        getARoles,
        deactivateUser,
        activateUser,
        updateUser,
        deactivateUsers: state.deactivateUsers,
        updateRole,
        auditTrail,
        addUserToRoles,
        audit: state.audit,
        users: state.users,
        roles: state.roles,
        arole: state.arole,
        updateUsers: state.updateUsers,
        updateFormState: state.updateFormState,
        addUsers: state.addUsers,
        permissions: state.permissions,
        usersOnARole: state.usersOnARole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
