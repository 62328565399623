import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { apiToken } from "../context/api-helpers";
import Denial from "../permissions/DenialPage/Denial";
import { Permission } from "../permissions/permissionAction";
import { DASHBOARD, HOME } from "./Constant";

const useAuth = () => {
  const user = apiToken?.accessToken;

  if (user !== null) {
    return user;
  }
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return !isAuth ? <Navigate to={HOME} /> : <Outlet />;
};

const LoggedInRoute = () => {
  const isAuth = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to={DASHBOARD} />;
};

const AppRoute = (KEY, route) => {
  return Permission(KEY) ? route : <Denial />;
};

export { ProtectedRoutes, useAuth, LoggedInRoute, AppRoute };
