import api, { formApi } from "../api";
import { apiToken } from "../api-helpers";
import apiAccess from "../apiAccess";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + apiToken?.accessToken,
};

const supportURL = "identity/Admin/users";

const customersActions = () => {
  return {
    deleteProduct: function (data) {
      const { agentId } = data;
      return api.post(
        `identity/ProductAdmin/products/deactivate/${agentId}?agentId=${agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    enableProduct: function (data) {
      const { agentId } = data;
      return api.post(
        `identity/ProductAdmin/products/activate/${agentId}?agentId=${agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    searchAgentByEmailorPhoneNumber: function (data) {
      const { emailAddress, mobileNumber } = data;
      return api.get(
        `${supportURL}/user?EmailAddress=${emailAddress}&MobileNumber=${mobileNumber}`,
        {
          headers: headers,
        }
      );
    },

    uploadReseller: function (data) {
      const searchParams = new URLSearchParams(window.location.search);
      const superDealerId = searchParams.get("uplineId");
      return api.post(
        `${supportURL}/reseller/upload?superDealerId=${Number(superDealerId)}`,
        data,
        {
          headers: headers,
        }
      );
    },

    deactivateCustomer: function (data) {
      return api.post(
        `identity/Admin/agents/deactivateuser`,
        { agentId: data?.userId, comment: "deactivate" },
        {
          headers: headers,
        }
      );
    },

    activateCustomer: function (data) {
      return api.post(
        `identity/Admin/agents/activateuser`,
        { agentId: data?.userId, comment: "activate" },
        {
          headers: headers,
        }
      );
    },

    addUser: function (data) {
      return api.post(`${supportURL}/systemuser/createuser`, data, {
        headers: headers,
      });
    },

    uploadAgent: function (data) {
      const searchParams = new URLSearchParams(window.location.search);
      const upline = searchParams.get("upline");
      const uplineId = searchParams.get("uplineId");
      const superDealerId = searchParams.get("superDealerId");
      const resellerId = searchParams.get("reseller");
      return api.post(
        `${supportURL}/agent/upload?uplineType=${upline || 5}&uplineId=${
          upline === 4 ? resellerId : upline === 5 ? superDealerId : uplineId
        }`,
        data,
        {
          headers: headers,
        }
      );
    },

    getUser: function (data) {
      const { page, searchText, roleId, filterDate, userState, pageSize } =
        data;
      return api.get(
        `${supportURL}?PageSize=${
          pageSize === undefined ? 15 : pageSize
        }&CurrentPage=${page === undefined ? 1 : page}&SearchText=${
          searchText === undefined ? "" : searchText
        }&RoleId=${roleId === undefined ? "" : roleId}&FilterDate=${
          filterDate || ""
        }&UserState=${userState === undefined ? -1 : userState}`,
        {
          headers: headers,
        }
      );
    },

    getAUser: function (userId) {
      return api.get(`identity/Admin/${userId}/user`, {
        headers: headers,
      });
    },

    deactivateUser: function (data) {
      return api.post(
        `${supportURL}/deactivateuser`,
        { userId: data?.userId, comment: "deactivate" },
        {
          headers: headers,
        }
      );
    },

    activateUser: function (data) {
      return api.post(
        `${supportURL}/activateuser`,
        { userId: data?.userId, comment: "activate" },
        {
          headers: headers,
        }
      );
    },

    updateUser: function (data) {
      return api.post(`${supportURL}/updateuser`, data, {
        headers: headers,
      });
    },

    getAgents: function (data) {
      const { pageSize, currentPage, searchText } = data;
      return api.get(
        `/identity/Admin/agents?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${searchText}&CustomerRoleId=${""}`,
        {
          headers: headers,
        }
      );
    },

    getAnAgent: function (data) {
      return api.get(`/identity/Admin/agents/${data}?id=${data}`, {
        headers: headers,
      });
    },

    getResellers: function (data) {
      const { pageSize, currentPage, searchText, SuperdealerID } = data;
      return api.get(
        `/identity/Admin/reseller?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${searchText}&SuperdealerID=${
          SuperdealerID || ""
        }`,
        {
          headers: headers,
        }
      );
    },

    getTellers: function (data) {
      const { pageSize, currentPage, searchText, SuperdealerID } = data;
      return api.get(
        `/identity/Admin/tellers?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${searchText}&SuperdealerID=${
          SuperdealerID || ""
        }`,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiToken?.accessToken,
          PARTNERID: "ACCESSBANKSERIEALONE",
        }
      );
    },

    getAReseller: function (data) {
      return api.get(`/identity/Admin/reseller/${data}?walletid=${data}`, {
        headers: headers,
      });
    },

    getAResellerProduct: function (data) {
      return api.get(
        `/identity/ProductAdmin/products/reseller/${data}?reseller=${data}`,
        {
          headers: headers,
        }
      );
    },

    getAnAgentProduct: function (data) {
      return api.get(
        `/identity/ProductAdmin/products/agent/${data}?agentId=${data}`,
        {
          headers: headers,
        }
      );
    },

    getAnAgentSubWallets: function (data) {
      return api.get(`/identity/Admin/subwallet/${data}?id=${data}`, {
        headers: headers,
      });
    },
    addSuperDealer: function (data) {
      return api.post(`${supportURL}/superdealer/createuser`, data, {
        headers: headers,
      });
    },

    addSuperDealerWithoutWallet: function (data) {
      return api.post(`${supportURL}/superdealer/createuser`, data, {
        headers: headers,
        // headers: {
        //   "Content-Type": "application/json",
        //   Authorization: "Bearer " + apiToken?.accessToken,
        //   PartnerId: "ACCESSBANKSERIEALONE",
        // },
      });
    },

    addReseller: function (data) {
      return api.post(`${supportURL}/reseller/createuser`, data, {
        headers: headers,
      });
    },

    upgradeAgent: function (data) {
      return api.post(`${supportURL}/upgrade`, data, {
        headers: headers,
      });
    },

    addAgent: function (data) {
      return api.post(`${supportURL}/agent/createuser`, data, {
        headers: headers,
      });
    },

    addAgent_corporate: function (data) {
      return api.post(`${supportURL}/corporate/createuser`, data, {
        headers: headers,
      });
    },

    resendActivation: function (data) {
      return api.post(`${supportURL}/resendactivationlink`, data, {
        headers: headers,
      });
    },

    // apiAccess

    resendActivationAccess: function (data) {
      return apiAccess.post(`${supportURL}/resendactivationlink`, data, {
        headers: headers,
      });
    },

    addAgent_corporate_upload: function (data) {
      const x = new URLSearchParams(window.location.search);
      console.log(x.get("t"));
      return formApi.post(
        `identity/Admin/documents/upload/${x.get("t")}?agentId=${x?.get("t")}`,
        data
      );
    },

    getAgent_file: function (data) {
      return api.get(`identity/Admin/documents/${data}`, data);
    },

    getAgent_file_download: function (data) {
      return api.get(`identity/Admin/document/${data}?id=${data}`, data, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      });
    },

    editSuperDealer: function (data) {
      return api.post(`${supportURL}/superdealer/updateuser`, data, {
        headers: headers,
      });
    },

    editReseller: function (data) {
      return api.post(`${supportURL}/reseller/updateuser`, data, {
        headers: headers,
      });
    },

    editAgent: function (data) {
      return api.post(`${supportURL}/agent/updateuser`, data, {
        headers: headers,
      });
    },

    editTeller: function (data) {
      return api.post(`${supportURL}/teller/updateuser`, data, {
        headers: headers,
      });
    },

    AddMultipleProductsAgent: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/mutiple/agent/${data.agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    getASuperDealerProduct: function (data) {
      return api.get(
        `/identity/ProductAdmin/products/superdealer/${data}?superDealerId=${data}`,
        {
          headers: headers,
        }
      );
    },

    getSuperDealers: function (data) {
      const { pageSize, currentPage, searchText } = data;
      return api.get(
        `/identity/Admin/superdealer?PageSize=${pageSize}&CurrentPage=${currentPage}&SearchText=${
          searchText || ""
        }`,
        {
          headers: headers,
        }
      );
    },

    getSuperDealerResellerAgents: function (data) {
      const {
        PageSize,
        CurrentPage,
        SearchText,
        ParentId,
        Filter,
        CustomerType,
      } = data;

      return api.get(
        `/identity/Admin/agents?PageSize=${PageSize}&CurrentPage=${CurrentPage}&SearchText=${
          SearchText || ""
        }&Filter=${Filter}&ParentId=${ParentId}&CustomerType=${CustomerType}`,
        {
          headers: headers,
        }
      );
    },

    getASuperDealer: function (data) {
      return api.get(`/identity/Admin/superdealer/${data}?walletid=${data}`, {
        headers: headers,
      });
    },
    updateAgentProduct: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/update/agent/${data?.agentId}?agentId=${data?.agentId}&resellerId=${data?.resellerId}`,
        data,
        {
          headers: headers,
        }
      );
    },
    updateResellerProduct: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/bulkupdate/reseller/${data?.agentId}?resellerId=${data?.agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },
    updateSuperDealerProduct: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/bulkupdate/superdealer/${data?.agentId}?superdealerId=${data?.agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    AddMultipleProductsSuperdealer: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/mutiple/superdealer/${data.agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    AddMultipleProductsReseller: function (data) {
      return api.post(
        `/identity/ProductAdmin/products/mutiple/reseller/${data.agentId}`,
        data,
        {
          headers: headers,
        }
      );
    },

    //Wallet Balances
    getWallet: function (data) {
      return api.get(`/wallet/Admin/walletbalances?WalletId=${data}`, {
        headers: headers,
      });
    },

    getTransactions: function (data) {
      const {
        WalletId,
        StartDate,
        EndDate,
        PageSize,
        CurrentPage,
        ServiceType,
        SearchText,
      } = data;

      return api.get(
        `/wallet/Admin/wallethistory?WalletId=${WalletId}&StartDate=${StartDate}&EndDate=${EndDate}&PageSize=${PageSize}&CurrentPage=${CurrentPage}&ServiceType=${ServiceType}&SearchText=${
          SearchText || ""
        }`,
        {
          headers: headers,
        }
      );
    },
    getTransactionsHistory: function (data) {
      const {
        WalletId,
        StartDate,
        EndDate,
        PageSize,
        CurrentPage,
        ServiceType,
        SearchText,
      } = data;

      return api.get(
        `/wallet/Admin/transactionhistory?WalletId=${WalletId}&StartDate=${StartDate}&EndDate=${EndDate}&PageSize=${PageSize}&CurrentPage=${CurrentPage}&ServiceType=${
          ServiceType || 2
        }&SearchText=${SearchText || ""}`,
        {
          headers: headers,
        }
      );
    },

    getProducts: function (data) {
      const {
        WalletId,
        StartDate,
        EndDate,
        PageSize,
        CurrentPage,
        ServiceType,
        SearchText,
      } = data;

      return api.get(
        `/wallet/Admin/transactionhistory?WalletId=${WalletId}&StartDate=${StartDate}&EndDate=${EndDate}&PageSize=${PageSize}&CurrentPage=${CurrentPage}&ServiceType=${
          ServiceType || 2
        }&SearchText=${SearchText || ""}`,
        {
          headers: headers,
        }
      );
    },
    getProviders: function (data) {
      const { id } = data;
      return api.get(
        `identity/ProductAdmin/products/${id}?PageSize=10000&CurrentPage=1`,
        {
          headers: headers,
        }
      );
    },

    getAllProducts: function (data) {
      return api.get(
        `identity/ProductAdmin/products?PageSize=10000&CurrentPage=1`,
        {
          headers: headers,
        }
      );
    },

    getAllProductsByUpline: function (data) {
      return api.get(
        `identity/ProductAdmin/products/upline?AgentId=${data}&PageSize=10000&CurrentPage=1`,
        {
          headers: headers,
        }
      );
    },

    getProviderBalance: function (data) {
      // const {
      //   WalletId,
      //   StartDate,
      //   EndDate,
      //   PageSize,
      //   CurrentPage,
      //   ServiceType,
      //   SearchText,
      // } = data;

      return api.get(`/wallet/Admin/providers/balance`, {
        headers: headers,
      });
    },
  };
};

export const CustomerServices = {
  customerActions: customersActions,
};
