export const ProductTypes = {
  PRODUCTS: "PRODUCTS",
  SERVICES: "SERVICES",
  PRODUCT: "PRODUCT",
  SERVICE: "SERVICE",
  ACTIVATE_PRODUCT: "ACTIVATE_PRODUCT",
  DEACTIVATE_PRODUCT: "DEACTIVATE_PRODUCT",
  ACTIVATE_SERVICE: "ACTIVATE_SERVICE",
  DEACTIVATE_SERVICE: "DEACTIVATE_SERVICE",

  GET_PRODUCTS: "GET_PRODUCTS",
  GET_SERVICE: "GET_SERVICES",


  CREATE_SERVICE: "CREATE_SERVICE",
  CREATE_PRODUCT: "CREATE_PRODUCT",

  GET_PROVIDER: "GET_PROVIDER"
};
