import React from "react";

function Denial() {
  return (
    <div>
      You don't have sufficient permissions to view this page. Contact
      Administrator
    </div>
  );
}

export default Denial;
